






































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  computed: {
    ...mapGetters({
      authenticated: "authenticated/isAuthenticated",
      user: "authenticated/user",
    }),
  },
  methods: {
    ...mapActions({
      signOut: "authenticated/signOut",
    }),
    misDatos() {
      this.$router.push({ name: "SiteBuilding" });
    },
    async logout() {
      await this.signOut();

      this.$router.replace({ name: "Login" });
    },
  },
});
