

























































import { IEvaluation } from "@/data/Evaluations/IEvaluation";
import IPagination from "@/data/Pagination/IPagination";
import { IVisit } from "@/data/Visits/Visit";
import getData from "@/utils/getData";
import axios from "axios";
import Vue from "vue";
import SelectUser from "../Users/SelectUser.vue";
import EvaluationsByUser from "./EvaluationsByUser.vue";
import VisitsReports from "./VisitsReports.vue";
export default Vue.extend({
  components: { SelectUser, VisitsReports, EvaluationsByUser },
  data() {
    const dateRange = {
      from: "",
      to: "",
    };
    const visits: IVisit[] = [];
    const evaluations: IEvaluation[] = [];
    const paginationVisits: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    const paginationEval: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    return {
      dateRange,
      visits,
      evaluations,
      paginationVisits,
      paginationEval,
      user: 0,
    };
  },
  methods: {
    selectUser(user: number) {
      this.user = user;
    },
    getVisits(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      const request = {
        from: getData(new Date(this.dateRange.from)),
        to: getData(new Date(this.dateRange.to)),
      };
      axios
        .post(`api/reports/user/${this.user}/visits${url}`, request)
        .then((res) => {
          this.visits = res.data.data;
          this.paginationVisits = res.data.pagination;
        });
    },
    getEvaluations(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      const request = {
        from: getData(new Date(this.dateRange.from)),
        to: getData(new Date(this.dateRange.to)),
      };
      axios
        .post(`api/reports/user/${this.user}/evaluations${url}`, request)
        .then((res) => {
          this.evaluations = res.data.data;
          this.paginationEval = res.data.pagination;
        });
    },
    searchReports() {
      this.getVisits();
      this.getEvaluations();
    },
  },
});
