





























































import { IAspects } from "@/data/Evaluations/IEvaluation";
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    aspects: {
      type: Array as PropType<IAspects[]>,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return { itemAspect: "" };
  },
  methods: {
    addAspect() {
      if (this.itemAspect !== "") {
        this.aspects.push({
          description: this.itemAspect,
          total: false,
          parcial: false,
          percentage: 0,
          type: this.type,
        });
      }
      this.itemAspect = "";
    },
    changeAspect(event: boolean, idx: number, fulfilled: number) {
      //total
      if (fulfilled == 1) {
        this.aspects[idx].total = event;
      }
      //parcial
      if (fulfilled == 2) {
        this.aspects[idx].parcial = event;
      }
    },
    delAspect(idx: number) {
      this.aspects.splice(idx, 1);
    },
  },
});
