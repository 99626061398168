











import Vue from "vue";

export default Vue.extend({
  name: "navbar-toggle",
  methods: {
    handleClick() {
      this.$bus.$emit("toggle-sidebar");
    },
  },
});
