































import Vue from "vue";
import MessageAlert from "../../components/Utils/MessageAlert.vue";
import RoleForm from "../../components/Roles/RoleForm.vue";
import IRoleFormAttributes from "../../data/Roles/IRoleFormAttributes";
import IRoleFormErrorAttributes from "../../data/Roles/IRoleFormErrorAttributes";
import { mapActions, mapGetters } from "vuex";
import Messages from "../../data/Messages/Messages";
import axios from "axios";
import IPermission from "../../data/Permissions/IPermission";
export default Vue.extend({
  components: { MessageAlert, RoleForm }, //
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getRole();
    if (this.isCreated) {
      this.optionsMessageAlert = Messages.SUCCESS_CREATED;
      this.showMessageAlert = true;
    }
  },
  data() {
    const roleFormAttributes: IRoleFormAttributes = {
      name: "",
    };
    const roleFormErrorAttributes: IRoleFormErrorAttributes = {
      name: [],
    };
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    const listPermissions: string[] = [];
    return {
      roleFormAttributes,
      roleFormErrorAttributes,
      showMessageAlert: false,
      optionsMessageAlert,
      listPermissions,
      isReady: false,
    };
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
      createdOff: "notifications/createdOff",
      updatedOff: "notifications/updatedOff",
      deleteOff: "notifications/deleteOff",
    }),
    goBack() {
      this.$router.push({ name: "Roles" });
    },
    permisos(listPermissions: IPermission[]): string[] {
      const lista: string[] = [];
      listPermissions.map((elemento: IPermission) => {
        lista.push(elemento.name);
      });
      this.isReady = true;
      return lista;
    },
    getRole() {
      axios.get(`/api/roles/${this.$route.params.id}`).then((res) => {
        this.roleFormAttributes = res.data as IRoleFormAttributes;
        this.listPermissions = this.permisos(res.data.permissions);
      });
    },
    saveRole() {
      this.roleFormErrorAttributes = {
        name: [],
      };
      const payload = {
        role: this.roleFormAttributes,
        permissions: this.listPermissions,
      };
      axios.put(`/api/roles/${this.$route.params.id}`, payload).then((res) => {
        this.optionsMessageAlert = Messages.SUCCESS_UPDATED;
        this.showMessageAlert = true;
        this.getRole();
        //eslint-disable-next-line
        const storPermisos: any = [];
        //eslint-disable-next-line
        res.data.permissions.forEach((element: any) => {
          storPermisos.push(element.name);
        });
        localStorage.setItem("permissions", JSON.stringify(storPermisos));
      });
    },

    closeAlert() {
      this.showMessageAlert = false;
      this.createdOff();
      this.updatedOff();
      this.deleteOff();
    },
    /* eslint-disable */
    changePermissions(newSelected: any[]) {
      this.listPermissions = newSelected;
    },
  },
  computed: {
    ...mapGetters({
      isCreated: "notifications/isCreated",
    }),
  },
});
