import Messages from "@/data/Messages/Messages";
import INotification from "@/data/Notifications/INotification";
import IStateNotifications from "../resources/IStateNotifications";

export default {
  namespaced: true,

  state: {
    isCreated: false,
    isUpdate: false,
    isDelete: false,
    showMessageAlert: false,
    optionsMessageAlert: {
      theme: "light",
      message: "",
    },
  },
  getters: {
    isCreated(state: IStateNotifications): boolean {
      return state.isCreated;
    },
    isUpdate(state: IStateNotifications): boolean {
      return state.isUpdate;
    },
    isDelete(state: IStateNotifications): boolean {
      return state.isDelete;
    },
    showMessageAlert(state: IStateNotifications): boolean {
      return state.showMessageAlert;
    },
    optionsMessageAlert(state: IStateNotifications): INotification {
      return state.optionsMessageAlert;
    },
  },
  mutations: {
    SET_IS_CREATED(state: IStateNotifications, value: boolean): void {
      state.isCreated = value;
      state.optionsMessageAlert = Messages.SUCCESS_CREATED;
      state.showMessageAlert = true;
    },
    SET_IS_UPDATED(state: IStateNotifications, value: boolean): void {
      state.isUpdate = value;
      state.optionsMessageAlert = Messages.SUCCESS_UPDATED;
      state.showMessageAlert = true;
    },
    SET_IS_DELETE(state: IStateNotifications, value: boolean): void {
      state.isDelete = value;
      state.optionsMessageAlert = Messages.SUCCESS_DELETED;
      state.showMessageAlert = true;
    },
    SET_IS_CREATED_OFF(state: IStateNotifications, value: boolean): void {
      state.isCreated = value;
      state.optionsMessageAlert = {
        theme: "light",
        message: "",
      };
      state.showMessageAlert = false;
    },
    SET_IS_UPDATED_OFF(state: IStateNotifications, value: boolean): void {
      state.isUpdate = value;
      state.optionsMessageAlert = {
        theme: "light",
        message: "",
      };
      state.showMessageAlert = false;
    },
    SET_IS_DELETE_OFF(state: IStateNotifications, value: boolean): void {
      state.isDelete = value;
      state.optionsMessageAlert = {
        theme: "light",
        message: "",
      };
      state.showMessageAlert = false;
    },
    SET_SHOW_MESSAGE_ALERT(state: IStateNotifications, value: boolean): void {
      state.showMessageAlert = value;
    },
    SET_OPTIONS_MESSAGE_ALERT(
      state: IStateNotifications,
      value: INotification
    ): void {
      state.optionsMessageAlert = value;
    },
  },
  actions: {
    /* eslint-disable */
    created({ commit }: any) {
      commit("SET_IS_CREATED", true);
    },
    /* eslint-disable */
    updated({ commit }: any) {
      commit("SET_IS_UPDATED", true);
    },
    /* eslint-disable */
    delete({ commit }: any) {
      commit("SET_IS_DELETE", true);
    },
    /* eslint-disable */
    createdOff({ commit }: any) {
      commit("SET_IS_CREATED_OFF", false);
    },
    /* eslint-disable */
    updatedOff({ commit }: any) {
      commit("SET_IS_UPDATED_OFF", false);
    },
    /* eslint-disable */
    deleteOff({ commit }: any) {
      commit("SET_IS_DELETE_OFF", false);
    },
    /* eslint-disable */
    closeAlert({ commit }: any) {
      commit("SET_SHOW_MESSAGE_ALERT", false);
    },
  },
};
