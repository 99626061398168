

















import Vue, { PropType } from "vue";
import { IModulePermission } from "../../data/Permissions/IPermission";
export default Vue.extend({
  props: {
    selected: {
      type: Array as PropType<string[]>,
    },
    permissions: {
      type: Array as PropType<IModulePermission[]>,
    },
  },
  computed: {
    cmp: {
      get: function (): string[] {
        return this.selected;
      },
      set: function (newValue: string[]) {
        this.$emit("changePermissions", newValue);
      },
    },
  },
});
