











import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";
import IRole from "../../data/Roles/IRole";
export default Vue.extend({
  props: {
    selected: {
      default: 1,
    },
  },
  created() {
    this.getRoles();
  },
  data() {
    const roles: IRole[] = [];
    return { roles };
  },
  methods: {
    getRoles() {
      axios
        .get(`/api/roles-select`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          this.roles = res.data as IRole[];
        });
    },
    changeRole(event: number) {
      this.select = event;
    },
  },
  computed: {
    ...mapGetters({
      token: "authenticated/token",
    }),
    select: {
      get: function (): number {
        return this.selected;
      },
      set: function (newValue: number) {
        // console.log(newValue);
        this.$emit("changeRole", newValue);
      },
    },
  },
});
