










































































































import axios, { AxiosError } from "axios";
import Vue from "vue";
import selectDocumentType from "../../components/Users/selectDocumentType.vue";
import HelperTextCodeErrorApi from "../../components/Utils/HelperTextCodeErrorApi.vue";
import { IProfileForm, IProfileFormError } from "../../data/Users/User";
import Messages from "../../data/Messages/Messages";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: { selectDocumentType, HelperTextCodeErrorApi },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    this.getProfile();
  },
  data() {
    const userFormAttributes: IProfileForm = {
      name: "",
      email: "",
      last_name: "",
      phone: "",
      document_type: 1,
      document_number: "",
    };
    const userFormErrorAttributes: IProfileFormError = {
      name: [],
      email: [],
      last_name: [],
      phone: [],
      document_type: [],
      document_number: [],
    };
    return { userFormAttributes, userFormErrorAttributes };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    getProfile() {
      const request = {
        token: this.token,
      };
      axios.post(`/api/get-user`, request).then((res) => {
        this.userFormAttributes = res.data.user as IProfileForm;
      });
    },
    changeSelectDocumentType(document: number) {
      this.userFormAttributes.document_type = document;
    },
    saveProfile() {
      this.$store.commit("notifications/SET_SHOW_MESSAGE_ALERT", false);
      axios
        .put(`/api/my-profile`, this.userFormAttributes)
        .then(() => {
          this.$store.commit(
            "notifications/SET_OPTIONS_MESSAGE_ALERT",
            Messages.SUCCESS_UPDATED
          );
          this.$store.commit("notifications/SET_SHOW_MESSAGE_ALERT", true);
        })
        .catch((reason: AxiosError) => {
          if (reason.response?.status === 422) {
            this.userFormErrorAttributes = reason.response?.data.errors;
            this.$store.commit(
              "notifications/SET_OPTIONS_MESSAGE_ALERT",
              Messages.INVALID_FORM_VALIDATION
            );
          } else if (reason.response?.status === 500) {
            this.$store.commit(
              "notifications/SET_OPTIONS_MESSAGE_ALERT",
              Messages.INTERNAL_ERROR
            );
          }
          this.$store.commit("notifications/SET_SHOW_MESSAGE_ALERT", true);
        });
    },
  },
  computed: {
    ...mapGetters({
      token: "authenticated/token",
    }),
  },
});
