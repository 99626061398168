






















































































































































































import { IVisitDetail } from "@/data/Visits/Visit";
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    this.getVisit();
  },
  data() {
    const visit: IVisitDetail = {
      visit_type: "",
      date: "",
      id: 0,
      last_visit: "",
      sidewalk: "",
      property: "",
      municipality: "",
      model: "",
      family_code: 0,
      beneficiary_name: "",
      beneficiary_identification: "",
      beneficiary_phone: "",
      parcel_status: "",
      scheduled_tasks: "",
      commitments: [],
      tracing: [],
      name_makes_visit: "",
      document_makes_visit: "",
      name_receives_visit: "",
      document_receives_visit: "",
      phone_receives_visit: "",
    };
    return { visit };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Visits" });
    },
    editVisit() {
      this.$router.push({
        name: "VisitEdit",
        params: { id: String(this.$route.params.id) },
      });
    },
    getVisit() {
      axios.get(`/api/visits/${this.$route.params.id}/detail`).then((res) => {
        this.visit = res.data as IVisitDetail;
      });
    },
  },
});
