































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  created() {
    this.$store.commit("SET_LAYOUT", "public-layout");
  },
  data() {
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return {
      credentials: {
        email: "",
        password: "",
      },
      showMessageAlert: false,
      optionsMessageAlert,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "authenticated/isAuthenticated",
    }),
  },
  methods: {
    ...mapActions({
      signIn: "authenticated/signIn",
      // resetErrors: "authenticated/resetErrors",
    }),
    async login() {
      // // this.resetErrors();
      await this.signIn(this.credentials).then(() => {
        this.$router.push({ name: "Dashboard" });
        // console.log("inició sesión");
      });
      // setTimeout(() => this.$router.replace({ name: "Dashboard" }), 2000);
      // this.$router.replace({ name: "Dashboard" }); //redirije luego de iniciar sesión
    },
    closeAlert() {
      this.showMessageAlert = false;
      this.optionsMessageAlert = {
        theme: "light",
        message: "",
      };
    },
    forgotPassword() {
      this.$router.push({ name: "ForgotPassword" });
    },
  },
});
