












import Vue from "vue";

export default Vue.extend({
  props: {
    theme: {
      type: String,
      default: "light",
    },
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeAlert() {
      this.$emit("closeAlert");
    },
  },
});
