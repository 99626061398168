






















































































import Vue, { PropType } from "vue";
import TableAspects from "./TableAspects.vue";
import selectUser from "@/components/Users/SelectUser.vue";
import { IEvaluationTechnicalForm } from "@/data/Evaluations/IEvaluation";
import { required, minValue } from "vuelidate/lib/validators";
import ValidatonErrorSelect from "../ValidatonErrorSelect.vue";
export default Vue.extend({
  components: { TableAspects, selectUser, ValidatonErrorSelect }, //
  props: {
    evaluationForm: {
      type: Object as PropType<IEvaluationTechnicalForm>,
    },
  },
  data() {
    return {
      itemTechnical: "",
      user_required: "",
      submitted: false,
    };
  },
  validations: {
    evaluationForm: {
      user_id: { required, minValue: minValue(1) },
    },
  },
  methods: {
    saveEvaluationTechnical() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.evaluationForm.user_id > 0) {
        this.$emit("saveEvaluationTechnical");
      } else {
        this.user_required = "El usuario es requerido";
      }
    },
    selectUser(user: number) {
      this.evaluationForm.user_id = user;
    },
  },
});
