



































import axios from "axios";
import Vue, { PropType } from "vue";
import { IModulePermission } from "../../data/Permissions/IPermission";
import IRoleFormAttributes from "../../data/Roles/IRoleFormAttributes";
import IRoleFormErrorAttributes from "../../data/Roles/IRoleFormErrorAttributes";
import HelperTextCodeErrorApi from "../Utils/HelperTextCodeErrorApi.vue";
import SelectPermission from "./SelectPermission.vue";
export default Vue.extend({
  components: { HelperTextCodeErrorApi, SelectPermission }, //
  created() {
    this.getPermissions();
  },
  props: {
    roleFormAttributes: {
      type: Object as PropType<IRoleFormAttributes>,
    },
    roleFormErrorAttributes: {
      type: Object as PropType<IRoleFormErrorAttributes>,
    },
    listPermissions: {
      type: Array,
    },
  },
  data() {
    const permissions: IModulePermission[] = [];
    return { permissions, selected: this.listPermissions };
  },
  methods: {
    saveRole() {
      this.$emit("saveRole", this.roleFormAttributes);
    },
    getPermissions() {
      axios.get(`/api/permissions`).then((res) => {
        this.permissions = res.data;
      });
    },
    changePermissions(newSelected: string[]) {
      this.selected = newSelected;
      this.$emit("changePermissions", newSelected);
    },
  },
});
