

















































import FormEvaluationProducer from "@/components/Evaluations/FormEvaluationProducer.vue";
import FormEvaluationTechnical from "@/components/Evaluations/FormEvaluationTechnical.vue";
import {
  IEvaluationForm,
  IEvaluationTechnicalForm,
} from "@/data/Evaluations/IEvaluation";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import { IPropSelect } from "@/data/Properties/Property";
import axios from "axios";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  components: { FormEvaluationProducer, FormEvaluationTechnical },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    this.getEvaluationTypes();
  },
  data() {
    const evaluation_types: { id: number; name: string }[] = [];
    const evaluationProducerForm: IEvaluationForm = {
      property_id: 0,
      municipality: 0,
      departamento_id: 0,
      califications: [],
      evaluation_type_id: 1,
    };
    const evaluationTechnicalForm: IEvaluationTechnicalForm = {
      user_id: 0,
      califications: [],
      evaluation_type_id: 2,
      aspects: {
        technical_aspects: [
          // {
          //   id: 1,
          //   description: "descripcion tecnico",
          //   total: true,
          //   parcial: false,
          //   percentage: 12,
          //   type: "tecnico",
          // },
        ],
        social_aspects: [
          // {
          //   id: 2,
          //   description: "descripcion social",
          //   total: true,
          //   parcial: false,
          //   percentage: 12,
          //   type: "social",
          // },
        ],
        administrative_aspects: [
          // {
          //   id: 3,
          //   description: "administrativo",
          //   total: true,
          //   parcial: false,
          //   percentage: 12,
          //   type: "administrativo",
          // },
        ],
      },
    };
    const municipalities: ISelectMunicipality[] = [];
    const properties: IPropSelect[] = [];
    return {
      selected: 0,
      state: false,
      evaluation_types,
      evaluationProducerForm,
      evaluationTechnicalForm,
      municipalities,
      properties,
    };
  },
  methods: {
    ...mapActions({
      createdAction: "notifications/created",
    }),
    goBack() {
      this.$router.push({ name: "Evaluations" });
    },

    onChange(value: number) {
      if (value > 0) {
        this.state = true;
      }
      this.selected = value;
      this.getIndicators(value);
    },
    getEvaluationTypes() {
      this.evaluation_types = [
        { id: 1, name: "Beneficiario" },
        { id: 2, name: "Técnico o profesional" },
      ];
    },
    getIndicators(type: number) {
      if (type > 0) {
        axios.get(`api/evaluations/${type}`).then((res) => {
          if (type == 1) {
            this.evaluationProducerForm.califications = res.data.indicators;
          } else if (type == 2) {
            this.evaluationTechnicalForm.califications = res.data.indicators;
          }
        });
      }
    },
    saveEvaluationProducer() {
      if (this.evaluationProducerForm.property_id > 0) {
        axios.post(`api/producers`, this.evaluationProducerForm).then(() => {
          this.$router.push({ name: "Evaluations" });
        });
      }
    },
    saveEvaluationTechnical() {
      axios.post(`api/technicians`, this.evaluationTechnicalForm).then(() => {
        this.createdAction();
        this.$router.push({ name: "Evaluations" });
      });
    },
    selectProperty(prop: number) {
      this.evaluationProducerForm.property_id = prop;
    },
    selectMunicipality(mun: number, properties: IPropSelect[]) {
      this.properties = properties;
      this.evaluationProducerForm.municipality = mun;
      this.evaluationProducerForm.property_id = 0;
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.municipalities = municipalities;
      this.evaluationProducerForm.departamento_id = dpto;
      this.evaluationProducerForm.municipality = 0;
      this.evaluationProducerForm.property_id = 0;
    },
  },
});
