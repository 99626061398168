
































import FormVisit from "@/components/Visits/FormVisit.vue";
import { ISelectDepartamento } from "@/data/IDepartamento";
import { IProjectSelect } from "@/data/IProject";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import { IPropSelect } from "@/data/Properties/Property";
import {
  IInfPropertyVisit,
  IPersonMakeVisit,
  IVisitForm,
} from "@/data/Visits/Visit";
import axios from "axios";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import getDate from "../../utils/getData";
export default Vue.extend({
  components: { FormVisit },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getUbication();
  },
  mounted() {
    if (this.userAuth.id !== 0) {
      this.visitForm.user_id = this.userAuth.id;
      this.personMakeVisit.name_makes_visit = this.userAuth.name;
      this.personMakeVisit.document_makes_visit = this.userAuth.document;
    }
    this.getVisit();
  },
  data() {
    const infProperty: IInfPropertyVisit = {
      sidewalk: "",
      model: "",
      family_code: 0,
      beneficiary_name: "",
      beneficiary_identification: "",
      beneficiary_phone: "",
    };
    const visitForm: IVisitForm = {
      date: getDate(new Date()),
      property_id: 0,
      municipality_id: 0,
      departamento_id: 0,
      user_id: 0,
      name_receives_visit: "",
      document_receives_visit: "",
      phone_receives_visit: "",
      parcel_status: "",
      scheduled_tasks: "",
      commitments: [],
      tracing: [],
      latitude: "",
      longitude: "",
      project_id: 0,
      visit_type: "",
    };
    const personMakeVisit: IPersonMakeVisit = {
      name_makes_visit: "",
      document_makes_visit: "",
    };
    const municipalities: ISelectMunicipality[] = [];
    const properties: IPropSelect[] = [];
    return {
      infProperty,
      visitForm,
      personMakeVisit,
      isReady: false,
      id_visit: Number(this.$route.params.id),
      municipalities,
      properties,
      loading: true,
      last_visit: "",
    };
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
    }),
    goBack() {
      this.$router.push({ name: "Visits" });
    },
    getUbication() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((data) => {
          this.visitForm.latitude = String(data.coords.latitude);
          this.visitForm.longitude = String(data.coords.longitude);
        });
      }
    },
    getVisit() {
      axios
        .get(`/api/visits/${this.$route.params.id}`)
        .then((res) => {
          this.visitForm.project_id = res.data.project_id;
          this.visitForm.visit_type = res.data.visit_type;
          this.visitForm.date = res.data.date;
          this.visitForm.property_id = res.data.property.id;
          this.visitForm.municipality_id = res.data.municipality_id;
          this.visitForm.departamento_id = res.data.departamento_id;
          this.visitForm.user_id = res.data.user.id;
          this.visitForm.name_receives_visit = res.data.name_receives_visit;
          this.visitForm.document_receives_visit =
            res.data.document_receives_visit;
          this.visitForm.phone_receives_visit = res.data.phone_receives_visit;
          this.visitForm.parcel_status = res.data.parcel_status;
          this.visitForm.scheduled_tasks = res.data.scheduled_tasks;
          this.visitForm.commitments = res.data.commitments;
          this.visitForm.tracing = res.data.tracing ?? [];
          this.visitForm.latitude = res.data.latitude;
          this.visitForm.longitude = res.data.longitude;

          this.infProperty = res.data.property as IInfPropertyVisit;

          this.last_visit =
            res.data.property.last_visit !== null
              ? res.data.property.last_visit.date
              : "";
          this.visitForm.tracing =
            res.data.property.last_visit !== null
              ? res.data.property.last_visit.commitments
              : [];
        })
        .finally(() => {
          this.loading = false;
          this.isReady = true;
        });
    },
    saveVisit() {
      this.getUbication();
      console.log(this.visitForm);
      axios
        .put(`api/visits/${this.$route.params.id}`, this.visitForm)
        .then((res) => {
          // this.$router.push({ name: "VisitEdit", params: { id: res.data.id } });
          // this.$swal('Éxito')
          // this.updatedAction();
          console.log(res.data);
        });
    },
    selectProperty(prop: number) {
      this.visitForm.property_id = prop;
      const index = this.properties.findIndex(
        (item: IPropSelect) => item.id == this.visitForm.property_id
      );
      const property = this.properties[index];
      this.infProperty = {
        sidewalk: property.sidewalk,
        model: property.model,
        family_code: property.family_code,
        beneficiary_name: property.beneficiary_name,
        beneficiary_identification: property.beneficiary_identification,
        beneficiary_phone: property.beneficiary_phone,
      };

      this.last_visit =
        property.last_visit !== null ? property.last_visit.date : "";
      this.visitForm.tracing =
        property.last_visit !== null ? property.last_visit.commitments : [];
    },
    selectMunicipality(municipality: number, properties: IPropSelect[]) {
      this.visitForm.municipality_id = municipality;
      this.properties = properties;
      this.visitForm.property_id = 0;
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.municipalities = municipalities;
      this.visitForm.departamento_id = dpto;
      this.visitForm.municipality_id = 0;
    },
    selectProject(project: IProjectSelect) {
      this.visitForm.project_id = project.id;
      this.visitForm.visit_type = project.visit_type;
      this.visitForm.departamento_id = 0;
      this.visitForm.municipality_id = 0;
      this.visitForm.property_id = 0;
    },
  },
  computed: {
    ...mapGetters({
      userAuth: "authenticated/user",
      departamentos: "selecteds/departamentos",
    }),
  },
  watch: {
    userAuth() {
      if (this.userAuth.id !== 0) {
        this.visitForm.user_id = this.userAuth.id;
        this.personMakeVisit.name_makes_visit = this.userAuth.name;
        this.personMakeVisit.document_makes_visit = this.userAuth.document;
      }
    },
    departamentos() {
      if (!this.loading && this.departamentos.length > 0) {
        const index = this.departamentos.findIndex(
          (item: ISelectDepartamento) =>
            item.id == this.visitForm.departamento_id
        );
        if (index >= 0) {
          this.municipalities = this.departamentos[index].municipalities;
        }
      }
    },
    municipalities() {
      if (
        this.visitForm.municipality_id > 0 &&
        this.municipalities.length > 0
      ) {
        const index2 = this.municipalities.findIndex(
          (item: ISelectMunicipality) =>
            item.id == this.visitForm.municipality_id
        );
        if (index2 >= 0) {
          this.properties = this.municipalities[index2].properties;
        }
      }
    },
  },
});
