import { render, staticRenderFns } from "./selectMunicipality.vue?vue&type=template&id=163ce0f4&"
import script from "./selectMunicipality.vue?vue&type=script&lang=ts&"
export * from "./selectMunicipality.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports