























































































import Vue from "vue";
import axios, { AxiosError } from "axios";
import MessageAlert from "../../components/Utils/MessageAlert.vue";
import Pagination from "../../components/Utils/Pagination.vue";
import IPagination from "../../data/Pagination/IPagination";
import IUser from "../../data/Users/IUser";
import Messages from "../../data/Messages/Messages";
import { mapActions } from "vuex";
export default Vue.extend({
  components: { Pagination, MessageAlert },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getUsers();
  },
  data() {
    const users: IUser[] = [];
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return { users, pagination, showMessageAlert: false, optionsMessageAlert };
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
      createdOff: "notifications/createdOff",
      updatedOff: "notifications/updatedOff",
      deleteOff: "notifications/deleteOff",
    }),
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    getUsers(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      axios.get(`/api/users${url}`).then((res) => {
        this.users = res.data.data as IUser[];
        this.pagination = res.data.pagination as IPagination;
        if (this.users.length <= 0) {
          this.optionsMessageAlert = Messages.ITEMS_LIST_EMPTY;
          this.showMessageAlert = true;
        }
      });
    },
    createUser() {
      this.$router.push({ name: "UserCreate" });
    },
    associateTechnicals() {
      this.$router.push({ name: "TechnicianAssignment" });
    },
    editUser(id: number) {
      this.createdOff();
      this.updatedOff();
      this.deleteOff();
      this.$router.push({ name: "UserEdit", params: { id: String(id) } });
    },
    deleteUser(id: number) {
      axios
        .delete(`/api/users/${id}`)
        .then(() => {
          this.optionsMessageAlert = Messages.SUCCESS_DELETED;
          this.showMessageAlert = true;
          this.getUsers();
        })
        .catch((reason: AxiosError) => {
          if (reason.response?.status === 403) {
            this.optionsMessageAlert = Messages.NO_PERMITS;
            this.showMessageAlert = true;
          } else {
            this.optionsMessageAlert = Messages.INTERNAL_ERROR;
            this.showMessageAlert = true;
          }
        });
    },

    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getUsers(page)
    },
    closeAlert() {
      this.showMessageAlert = false;
    },
  },
});
