






import Vue, { PropType } from "vue";
import IValidationError, { IValidationParams } from "@/data/IValidationError";
export default Vue.extend({
  props: {
    propiedad: {
      type: Object as PropType<IValidationError>,
    },
    params: {
      type: Object as PropType<IValidationParams>,
    },
  },
  computed: {
    validatRequired() {
      if (this.propiedad?.required !== undefined && !this.propiedad?.required) {
        return "El campo es obligatorio";
      } else {
        return "";
      }
    },
    validateMinValue() {
      if (
        this.propiedad?.minValue !== undefined &&
        !this.propiedad?.minValue &&
        this.params.minValue !== undefined
      ) {
        let minimo = "";
        if (this.params.minValue.min !== undefined) {
          minimo = String(this.params?.minValue.min);
        }
        return `El campo debe ser mayor que ${minimo}`;
      } else {
        return "";
      }
    },
    validateMaxValue() {
      if (
        this.propiedad?.maxValue !== undefined &&
        !this.propiedad?.maxValue &&
        this.params.maxValue !== undefined
      ) {
        let maximo = "";
        if (this.params.maxValue.max !== undefined) {
          maximo = String(this.params?.maxValue.max);
        }
        return `El campo debe ser menor que ${maximo}`;
      } else {
        return "";
      }
    },
    validateDecimal() {
      if (this.propiedad?.decimal !== undefined && !this.propiedad?.decimal) {
        return "Se debe ingresar número decimal";
      }
      return "";
    },
    validateBetween() {
      if (this.propiedad?.between !== undefined && !this.propiedad?.between) {
        let minimo = "";
        let maximo = "";
        if (
          this.params.between?.min !== undefined &&
          this.params.between.max !== undefined
        ) {
          minimo = String(this.params.between?.min);
          maximo = String(this.params.between?.max);
        }
        return `El valor debe estar entre ${minimo} y ${maximo}`;
      }
      return "";
    },
    validateEmail() {
      if (this.propiedad?.email !== undefined && !this.propiedad?.email) {
        return "Debe ingresar un email válido";
      }
      return "";
    },
    validateMinLength() {
      if (
        this.propiedad?.minLength !== undefined &&
        !this.propiedad?.minLength &&
        this.params.minLength !== undefined
      ) {
        let minimo = "";
        if (this.params.minLength.min !== undefined) {
          minimo = String(this.params?.minLength.min);
        }
        return `El campo debe ser mayor que ${minimo}`;
      } else {
        return "";
      }
    },
  },
});
