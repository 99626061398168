import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import ShardsVue from "shards-vue";
import "bootstrap/dist/css/bootstrap.css";
import "@/scss/shards-dashboards.scss";
import "@/assets/scss/date-range.scss";
import EventBus from "vue-bus-ts";
import { ENVIRONMENT } from "../config/environment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ability from "./ability";
import { abilitiesPlugin } from "@casl/vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vuelidate from "vuelidate";

const options = {
  confirmButtonColor: "#17c671",
  cancelButtonColor: "#ff7674",
};
Vue.use(Vuelidate);
Vue.use(abilitiesPlugin, ability);
Vue.component("v-select", vSelect);
Vue.use(VueSweetalert2, options);
Vue.use(EventBus);
const bus = new EventBus.Bus();
export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? ENVIRONMENT.development.BASE_URL
    : ENVIRONMENT.production.BASE_URL;
ShardsVue.install(Vue);
Vue.config.productionTip = false;
axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

new Vue({
  router,
  store,
  bus,
  render: (h) => h(App),
}).$mount("#app");
