


























































































































































































































































import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import Vue, { PropType } from "vue";
import { IPropertyForm } from "../../data/Properties/Property";
import selectMunicipality from "../Municipalities/selectMunicipality.vue";
import SelectDepartamento from "../Selects/SelectDepartamento.vue";
import ValidationError from "../ValidationError.vue";
import {
  required,
  decimal,
  integer,
  minValue,
  maxValue,
  numeric,
  between,
} from "vuelidate/lib/validators";
import ValidatonErrorSelect from "../ValidatonErrorSelect.vue";
import Mapa from "../mapa.vue";

export default Vue.extend({
  components: {
    selectMunicipality,
    SelectDepartamento,
    ValidationError,
    ValidatonErrorSelect,
    Mapa,
  },
  props: {
    propertyForm: {
      type: Object as PropType<IPropertyForm>,
    },
    municipalities: {
      type: Array as PropType<ISelectMunicipality[]>,
      default: [],
    },
  },
  data() {
    return { submitted: false };
  },
  validations: {
    propertyForm: {
      name: { required },
      sidewalk: { required },
      latitude: { required, decimal, between: between(-90, 90) },
      longitude: { required, decimal, between: between(-180, 180) },
      altitude: { required, decimal, between: between(0, 4000) },
      area: { required, minValue: minValue(1) },
      model: { required },
      beneficiary_name: { required },
      beneficiary_identification: { required, numeric },
      beneficiary_phone: { required },
      departamento_id: { required, minValue: minValue(1) },
      municipality_id: { required, minValue: minValue(1) },
    },
  },
  methods: {
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.$emit("selectDepartamento", dpto, municipalities);
    },
    selectMunicipality(municipality: number) {
      this.$emit("selectMunicipality", municipality);
    },
    saveProperty() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit("saveProperty");
    },
  },
  computed: {
    coordenadas(): string {
      const { latitude, longitude } = this.propertyForm;
      if (latitude !== "" && longitude !== "") {
        const coord = latitude + "," + longitude;
        return coord;
      }
      return "BOGOTA";
    },
  },
});
