






























import Vue from "vue";
import UserForm from "../../components/Users/UserForm.vue";
import MessageAlert from "../../components/Utils/MessageAlert.vue";
import IUserFormAttributes from "../../data/Users/IUserFormAttributes";
import Messages from "../../data/Messages/Messages";
import { mapActions, mapGetters } from "vuex";
import IUserFormErrorAttributes from "../../data/Users/IUserFormErrorAttributes";
import axios, { AxiosError } from "axios";
export default Vue.extend({
  components: { UserForm, MessageAlert },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getUser();
    if (this.isCreated) {
      this.optionsMessageAlert = Messages.SUCCESS_CREATED;
      this.showMessageAlert = true;
    }
  },
  data() {
    const userFormAttributes: IUserFormAttributes = {
      name: "",
      email: "",
      last_name: "",
      phone: "",
      document_type: 1,
      document_number: "",
      role: 0,
    };
    const userFormErrorAttributes: IUserFormErrorAttributes = {
      name: [],
      email: [],
      last_name: [],
      phone: [],
      password: [],
      document_type: [],
      document_number: [],
    };
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return {
      userFormAttributes,
      userFormErrorAttributes,
      showMessageAlert: false,
      optionsMessageAlert,
    };
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
      createdOff: "notifications/createdOff",
      updatedOff: "notifications/updatedOff",
      deleteOff: "notifications/deleteOff",
    }),
    goBack() {
      this.$router.push({ name: "Users" });
    },
    getUser() {
      axios.get(`/api/users/${this.$route.params.id}`).then((res) => {
        this.userFormAttributes = res.data as IUserFormAttributes;
      });
    },
    saveUser() {
      this.userFormErrorAttributes = {
        name: [],
        email: [],
        last_name: [],
        phone: [],
        password: [],
        document_type: [],
        document_number: [],
      };
      axios
        .put(`/api/users/${this.$route.params.id}`, this.userFormAttributes)
        .then(() => {
          this.updatedAction();
          this.optionsMessageAlert = Messages.SUCCESS_UPDATED;
          this.showMessageAlert = true;
          // this.$router.push({ name: "UserEdit", params: { id: res.data.id } });
        })
        .catch((reason: AxiosError) => {
          this.userFormErrorAttributes = reason.response?.data.errors;
          if (reason.response?.status === 422) {
            this.optionsMessageAlert = Messages.INVALID_FORM_VALIDATION;
          } else if (reason.response?.status === 500) {
            this.optionsMessageAlert = Messages.INTERNAL_ERROR;
          }
          this.showMessageAlert = true;
        });
    },

    closeAlert() {
      this.showMessageAlert = false;
      this.createdOff();
      this.updatedOff();
      this.deleteOff();
    },
    changeRole(role: number) {
      this.userFormAttributes.role = role;
    },
  },
  computed: {
    ...mapGetters({
      isCreated: "notifications/isCreated",
    }),
  },
});
