



























import ByProperty from "@/components/Reports/ByProperty.vue";
import ByUser from "@/components/Reports/ByUser.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    ByProperty,
    ByUser,
  },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
  },
});
