















import Vue from "vue";

export default Vue.extend({
  props: {
    menuItems: {
      type: Array,
      default() {
        return [];
      },
    },
    copyright: {
      type: String,
      default: "Copyright © 2022 Cliptech SAS",
    },
    /**
     * Whether the footer should be wrapped in a container, or not.
     */
    contained: {
      type: Boolean,
      default: false,
    },
  },
});
