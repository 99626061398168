export interface IItemsSubnivel {
  title: string;
  to: { name: string };
}
export interface ISideBarNavItems {
  title: string;
  to: { name: string };
  items?: IItemsSubnivel[];
  htmlBefore: string;
  htmlAfter: string;
}
const getSidebarItems = (): ISideBarNavItems[] => {
  const items: ISideBarNavItems[] = [
    {
      title: "Panel de control",
      to: {
        name: "Dashboard",
      },
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: "",
    },
    {
      title: "Visitas técnicas",
      to: {
        name: "Visits",
      },
      htmlBefore: '<i class="material-icons">place</i>',
      htmlAfter: "",
    },
    {
      title: "Gestión",
      to: { name: "" },
      items: [
        {
          title: "Usuarios",
          to: {
            name: "Users",
          },
        },
        {
          title: "Roles",
          to: {
            name: "Roles",
          },
        },
        {
          title: "Municipios",
          to: {
            name: "Municipalities",
          },
        },
        {
          title: "Proyectos",
          to: {
            name: "Projects",
          },
        },
        {
          title: "Predios",
          to: {
            name: "ListProperties",
          },
        },
        {
          title: "Evaluaciones",
          to: {
            name: "Evaluations",
          },
        },
        // {
        //   title: "Hojas de vida",
        //   to: {
        //     name: "CurriculumVitae",
        //   },
        // },
      ],
      htmlBefore: '<i class="material-icons">supervised_user_circle</i>',
      htmlAfter: "",
    },
    {
      title: "Reportes",
      to: { name: "Reports" },
      htmlBefore: '<i class="material-icons">stacked_bar_chart</i>',
      htmlAfter: "",
    },
  ];
  return items;
};
export default getSidebarItems;
