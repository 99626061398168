































































































import SelectCoordinador from "@/components/Selects/SelectCoordinador.vue";
import SeletTechnicianMultiple from "@/components/Selects/SeletTechnicianMultiple.vue";
import MessageAlert from "@/components/Utils/MessageAlert.vue";
import Vue from "vue";
import { required, minValue, minLength } from "vuelidate/lib/validators";
import ValidatonErrorSelect from "@/components/ValidatonErrorSelect.vue";
import axios from "axios";
export default Vue.extend({
  components: {
    MessageAlert,
    SeletTechnicianMultiple,
    SelectCoordinador,
    ValidatonErrorSelect,
  },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getAssociations();
  },
  data() {
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    const users: { coordinator: string; tecnicos: string[] }[] = [];
    const asignacion: { coordinator: number; tecnicos: number[] } = {
      coordinator: 0,
      tecnicos: [],
    };
    return {
      showMessageAlert: false,
      optionsMessageAlert,
      users,
      asignacion,
      submitted: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Users" });
    },
    getAssociations() {
      axios.get(`/api/associations`).then((res) => {
        this.users = res.data;
      });
    },
    closeAlert() {
      this.showMessageAlert = false;
    },
    selectCoordinator(coordinador: number) {
      this.asignacion.coordinator = coordinador;
    },
    selectTechnicians(technicians: number[]) {
      this.asignacion.tecnicos = technicians;
    },
    associateTechnicals() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid || this.asignacion.tecnicos.length === 0) {
        return;
      }
      axios
        .put(`/api/assignment-coordinatos-technicians`, this.asignacion)
        .then(() => {
          this.submitted = false;
          this.getAssociations();
          this.$forceUpdate();
        });
    },
  },
  validations: {
    asignacion: {
      coordinator: { required, minValue: minValue(1) },
      tecnicos: { minLength: minLength(1) },
    },
  },
});
