import Vue from "vue";
import Vuex from "vuex";
import authenticated from "./modules/authenticated";
import notifications from "./modules/notifications";
import selecteds from "./modules/selecteds";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    layout: "public-layout",
    showModal: false,
    modal_title: "titulooo",
    modal_body: "h1",
  },
  mutations: {
    SET_LAYOUT(state, newLayout) {
      state.layout = newLayout;
    },
    SET_SHOW_MODAL(state) {
      state.showModal = !state.showModal;
    },
    SET_MODAL_TITLE(state, newModal) {
      state.modal_title = newModal;
    },
    SET_MODAL_BODY(state, newModal) {
      state.modal_body = newModal;
    },
  },
  actions: {
    //eslint-disable-next-line
    handleClose({ commit }: any) {
      commit("SET_SHOW_MODAL");
    },
  },
  modules: { authenticated, notifications, selecteds },
});

export default store;
