export const ENVIRONMENT: IEnvironment = {
    development: {
        BASE_URL: "http://localhost:8000/"
        // BASE_URL: "http://192.168.88.31:8000/"
    },
    production: {
        // BASE_URL: "http://localhost:8000/"
        BASE_URL: "https://api.proyectomonitoreo.com/public/"
    }
}

interface IEnvironment {
    development: INodeEnv
    production: INodeEnv
}

interface INodeEnv {
    BASE_URL: string
}