import IMessagesList from "./IMessageList";
const messagesList: IMessagesList = {
  INTERNAL_ERROR: {
    message:
      "Ha ocurrido un error interno y no se ha podido realizar el proceso, por favor intente de nuevo o más tarde, si el problema persiste debe comunicarse con el Administrador",
    theme: "danger",
  },
  INVALID_FORM_VALIDATION: {
    message: "No se ha podido guardar el registro, el formulario tiene errores",
    theme: "danger",
  },
  SUCCESS_CREATED: {
    message: "Registro ha sido creado con éxito",
    theme: "success",
  },
  SUCCESS_REGISTER: {
    message:
      "Se ha realizado el registro correctamente, inicie sesión con su correo electrónico y la contraseña <strong>password</strong>",
    theme: "success",
  },
  SUCCESS_MAIL: {
    message:
      "Se ha enviado un mensaje a su correo electrónico con una contraseña temporal para inicio de sesión. <strong>Recuerde cambiarla una vez inicie sesión</strong>",
    theme: "success",
  },
  SUCCESS_DELETED: {
    message: "Registro ha sido borrado con éxito",
    theme: "success",
  },
  SUCCESS_UPDATED: {
    message: "Registro ha sido actualizado con éxito",
    theme: "success",
  },
  SUCCESS_IMPORT: {
    message: "Se ha realizado correctamente la importación",
    theme: "success",
  },
  ITEMS_LIST_EMPTY: {
    message: "No se han encontrado registros asociados a la búsqueda.",
    theme: "primary",
  },
  INFO_SECTION: {
    message: "",
    theme: "info",
  },
  MESSAGE_SENT: {
    message: "Mensaje ha sido enviado con éxito",
    theme: "success",
  },
  NO_PERMITS: {
    message: "El usuario no tiene permisos para realizar esta acción",
    theme: "danger",
  },
};
export default messagesList;
