














































import MessageAlert from "@/components/Utils/MessageAlert.vue";
import Messages from "@/data/Messages/Messages";
import axios, { AxiosError } from "axios";
import Vue from "vue";
export default Vue.extend({
  components: { MessageAlert },
  data() {
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return {
      email: "",
      showMessageAlert: false,
      optionsMessageAlert,
      close: false,
    };
  },
  methods: {
    closeAlert() {
      this.showMessageAlert = false;
      this.optionsMessageAlert = {
        theme: "light",
        message: "",
      };
      if (this.close) {
        this.$router.push({ name: "Login" });
      }
    },
    forgotPassword() {
      const request = {
        email: this.email,
      };
      axios
        .post(`api/send-email`, request)
        .then(() => {
          this.optionsMessageAlert = Messages.SUCCESS_MAIL;
          this.close = true;
        })
        .catch((reason: AxiosError) => {
          if (reason.response?.status === 404) {
            this.optionsMessageAlert = {
              theme: "danger",
              message:
                "El correo electrónico ingresado no existe en nuestros registros",
            };
          } else {
            this.optionsMessageAlert = Messages.INTERNAL_ERROR;
          }
        })
        .finally(() => {
          this.showMessageAlert = true;
        });
    },
  },
});
