




import { ISelectDepartamento } from "@/data/IDepartamento";
import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  created() {
    this.getDptos();
  },
  props: {
    selected: {
      type: Number,
      default: 0,
    },
    project: {
      type: Number,
      default: 0,
    },
    reloadDptos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const departamentos: ISelectDepartamento[] = [];
    return {
      departamentos,
      state: false,
      ready: false,
    };
  },
  methods: {
    getDptos() {
      const url = this.project > 0 ? `?project=${this.project}` : "";
      axios
        .get(`/api/select-departamentos${url}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          this.departamentos = res.data;
          this.$store.commit(
            "selecteds/SET_IS_DEPARTAMENTOS",
            this.departamentos
          );
        })
        .finally(() => {
          this.ready = true;
        });
    },
  },
  computed: {
    seleccion: {
      get: function (): ISelectDepartamento {
        const index = this.departamentos.findIndex(
          (item) => item.id == this.selected
        );
        return this.departamentos[index];
      },
      set(newDpto: ISelectDepartamento) {
        this.$emit("selectDepartamento", newDpto.id, newDpto.municipalities);
      },
    },
    ...mapGetters({
      token: "authenticated/token",
    }),
  },
  watch: {
    reloadDptos() {
      if (this.reloadDptos) {
        this.getDptos();
      }
    },
  },
});
