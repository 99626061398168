



































































import Vue from "vue";
import IRole from "../../data/Roles/IRole";
import IPagination from "../../data/Pagination/IPagination";
import Pagination from "../../components/Utils/Pagination.vue";
import axios from "axios";
import Messages from "../../data/Messages/Messages";
import MessageAlert from "../../components/Utils/MessageAlert.vue";
export default Vue.extend({
  components: { Pagination, MessageAlert },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getRoles();
  },
  data() {
    const roles: IRole[] = [];
    const pagination: IPagination = {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
      from: 0,
      to: 0,
    };
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return {
      roles,
      pagination,
      showMessageAlert: false,
      optionsMessageAlert,
      isPermitRead: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    getRoles(page?: number) {
      axios.get(`/api/roles?page=${page}`).then((res) => {
        this.roles = res.data.data as IRole[];
        // this.pagination = res.data.pagination
      });
    },
    toCreateRole() {
      this.$router.push({ name: "RoleCreate" });
    },
    toEditRole(id: string) {
      this.$router.push({ name: "RoleEdit", params: { id: id } });
    },
    deleteRole(id: string) {
      axios
        .delete(`/api/roles/${id}`)
        .then(() => {
          this.optionsMessageAlert = Messages.SUCCESS_DELETED;
          this.showMessageAlert = true;
          this.getRoles();
        })
        .catch(() => {
          this.optionsMessageAlert = Messages.INTERNAL_ERROR;
          this.showMessageAlert = true;
        });
    },

    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getRoles(page);
    },
    closeAlert() {
      this.showMessageAlert = false;
    },
  },
});
