
































































































import Vue from "vue";
import IMunicipalities from "@/data/IMunicipalities";
import axios from "axios";
import IPagination from "@/data/Pagination/IPagination";
import IDepartamento from "@/data/IDepartamento";
import Pagination from "@/components/Utils/Pagination.vue";
export default Vue.extend({
  components: { Pagination },
  mounted() {
    this.getDepartamentos();
    this.getMunicipalities();
  },
  data() {
    const municipalities: IMunicipalities[] = [];
    const departamentos: IDepartamento[] = [];
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    return {
      municipalities,
      pagination,
      departamentos,
      mun: "",
      dpto: 0,
      query: "",
    };
  },
  methods: {
    getDepartamentos() {
      axios.get(`/api/select-departamentos`).then((res) => {
        this.departamentos = res.data as IDepartamento[];
      });
    },
    getMunicipalities(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      axios.get(`/api/municipalities${url}`).then((res) => {
        this.municipalities = res.data.data as IMunicipalities[];
        this.pagination = res.data.pagination as IPagination;
      });
    },
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    searchMunicipalities() {
      this.query = `&&municipality=${this.mun}`;
      this.dpto > 0 ? (this.query += `&&departamento=${this.dpto}`) : "";
      this.getMunicipalities(1, this.query);
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getMunicipalities(page, this.query);
    },
    changeStatus(id: number, status: boolean) {
      const request = {
        status: status,
      };
      axios.post(`/api/municipalities/${id}/status`, request).then(() => {
        this.getMunicipalities(this.pagination.current_page, this.query);
      });
    },
  },
});
