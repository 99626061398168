




















































































































































































































































































































































import { IProjectSelect } from "@/data/IProject";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import { IPropSelect } from "@/data/Properties/Property";
import {
  IInfPropertyVisit,
  IPersonMakeVisit,
  IVisitForm,
} from "@/data/Visits/Visit";
import Vue, { PropType } from "vue";
import Mapa from "../mapa.vue";
import selectMunicipality from "../Municipalities/selectMunicipality.vue";
import SelectDepartamento from "../Selects/SelectDepartamento.vue";
import SelectProject from "../Selects/SelectProject.vue";
import SelectProperty from "../Selects/SelectProperty.vue";
import ValidationError from "../ValidationError.vue";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import ValidatonErrorSelect from "../ValidatonErrorSelect.vue";
export default Vue.extend({
  components: {
    SelectDepartamento,
    Mapa,
    SelectProject,
    ValidationError,
    ValidatonErrorSelect,
    selectMunicipality,
    SelectProperty,
  },
  props: {
    infProperty: {
      type: Object as PropType<IInfPropertyVisit>,
    },
    visitForm: {
      type: Object as PropType<IVisitForm>,
    },
    personMakeVisit: {
      type: Object as PropType<IPersonMakeVisit>,
    },
    isMunicipality: {
      type: Boolean,
      default: false,
    },
    visit: {
      type: Number,
      default: 0,
    },
    municipalities: {
      type: Array as PropType<ISelectMunicipality[]>,
      default: [],
    },
    properties: {
      type: Array as PropType<IPropSelect[]>,
      default: [],
    },
    last_visit: {
      type: String,
      default: "",
    },
  },
  data() {
    const commitments: string[] = [];
    return {
      thereIsProperty: false,
      commitment: "",
      commitments,
      other_type: "",
      // last_visit: "",
      submitted: false,
      reloadDptos: false,
    };
  },
  validations: {
    visitForm: {
      date: { required },
      property_id: { required, minValue: minValue(1) },
      user_id: {},
      municipality_id: { required, minValue: minValue(1) },
      departamento_id: { required, minValue: minValue(1) },
      name_receives_visit: { required },
      document_receives_visit: { required },
      phone_receives_visit: { required },
      parcel_status: { required },
      scheduled_tasks: { required },
      commitments: { minLength: minLength(0) },
      tracing: { minLength: minLength(0) },
      latitude: { required },
      longitude: { required },
      project_id: { required, minValue: minValue(1) },
    },
  },
  methods: {
    selectProject(project: IProjectSelect) {
      setTimeout(() => {
        this.reloadDptos = true;
      }, 1000);
      this.$emit("selectProject", project);
      this.reloadDptos = false;
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.$emit("selectDepartamento", dpto, municipalities);
    },
    selectMunicipality(mun: number, properties: IPropSelect[]) {
      this.$emit("selectMunicipality", mun, properties);
    },
    selectProperty(property: number) {
      // const index = this.properties.findIndex((item) => item.id == this.visitForm.property_id);
      // console.log(index)
      // this.infProperty.sidewalk = this.properties[index].sidewalk;
      // this.infProperty.model = this.properties[index].model;
      // this.infProperty.family_code = this.properties[index].property_id;
      // this.infProperty.beneficiary_name =
      //   this.properties[index].beneficiary_name;
      // this.infProperty.beneficiary_identification =
      //   this.properties[index].beneficiary_identification;
      // this.infProperty.beneficiary_phone =
      //   this.properties[index].beneficiary_phone;

      // this.last_visit =
      // this.properties[index].last_visit !== null
      //   ? this.properties[index].last_visit.date
      //   : "";
      // this.visitForm.tracing =
      //   this.properties[index].last_visit !== null
      //     ? this.properties[index].last_visit.commitments
      //     : [];
      this.$emit("selectProperty", property);
    },
    addCommitment() {
      if (this.commitment !== "") {
        this.visitForm.commitments.push(this.commitment);
      }
      this.commitment = "";
    },
    delCommitment(idx: number) {
      this.visitForm.commitments.splice(idx, 1);
    },
    deactivateCommitment(idx: number) {
      this.visitForm.tracing[idx].fulfilled = false;
    },
    activateCommitment(idx: number) {
      this.visitForm.tracing[idx].fulfilled = true;
    },
    saveVisit() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit("saveVisit");
    },
  },
  computed: {
    coordenadas(): string {
      const { latitude, longitude } = this.visitForm;
      if (latitude !== "" && longitude !== "") {
        const coord = latitude + "," + longitude;
        return coord;
      }
      return "BOGOTA";
    },
  },
});
