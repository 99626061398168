





































































import axios from "axios";
import Vue from "vue";
import Pagination from "../../components/Utils/Pagination.vue";
import IPagination from "../../data/Pagination/IPagination";
import { IPropertyList } from "../../data/Properties/Property";
export default Vue.extend({
  components: { Pagination },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getProperties();
  },
  data() {
    const properties: IPropertyList[] = [];
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    return { properties, pagination };
  },
  methods: {
    getProperties(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      axios.get(`/api/properties${url}`).then((res) => {
        this.properties = res.data.data as IPropertyList[];
        this.pagination = res.data.pagination as IPagination;
      });
    },
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    createProperty() {
      this.$router.push({ name: "CreateProperty" });
    },
    editProperty(property: string) {
      this.$router.push({ name: "EditProperty", params: { id: property } });
    },
    deleteProperty(property: string) {
      axios.delete(`/api/properties/${property}`).then(() => {
        this.getProperties();
      });
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getProperties(page);
    },
  },
});
