




























































































































import { IEvaluationForm } from "@/data/Evaluations/IEvaluation";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import { IBeneficiary, IPropSelect } from "@/data/Properties/Property";
import Vue, { PropType } from "vue";
import SelectMunicipality from "../Municipalities/selectMunicipality.vue";
import SelectDepartamento from "../Selects/SelectDepartamento.vue";
import SelectProperty from "../Selects/SelectProperty.vue";
import { required, minValue } from "vuelidate/lib/validators";
import ValidatonErrorSelect from "../ValidatonErrorSelect.vue";
export default Vue.extend({
  components: {
    SelectMunicipality,
    SelectDepartamento,
    SelectProperty,
    ValidatonErrorSelect,
  }, //SelectPropertyForMunicipality //SelectPropForMun
  props: {
    evaluationForm: {
      type: Object as PropType<IEvaluationForm>,
    },
    municipalities: {
      type: Array as PropType<ISelectMunicipality[]>,
      default: [],
    },
    properties: {
      type: Array as PropType<IPropSelect[]>,
      default: [],
    },
  },
  mounted() {
    if (this.evaluationForm.property_id > 0 && this.isLoading) {
      this.isLoading = false;
      this.thereIsMunicipality = true;
      this.selectProperty(this.evaluationForm.property_id);
    }
  },
  data() {
    const beneficiario: IBeneficiary = {
      beneficiary_name: "",
      beneficiary_identification: "",
      beneficiary_phone: "",
    };
    return {
      isLoading: true,
      thereIsMunicipality: false,
      thereIsProperty: false,
      beneficiario,
      property_required: "",
      submitted: false,
    };
  },
  validations: {
    evaluationForm: {
      property_id: { required, minValue: minValue(1) },
      municipality: { required, minValue: minValue(1) },
      departamento_id: { required, minValue: minValue(1) },
      califications: {},
      evaluation_type_id: { required, minValue: minValue(1) },
    },
  },
  methods: {
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.$emit("selectDepartamento", dpto, municipalities);
    },
    selectMunicipality(mun: number, properties: IPropSelect[]) {
      this.$emit("selectMunicipality", mun, properties);
    },
    selectProperty(property: number) {
      const index = this.properties.findIndex((item) => item.id == property);
      if (index >= 0) {
        this.beneficiario.beneficiary_name =
          this.properties[index].beneficiary_name ?? "";
        this.beneficiario.beneficiary_identification =
          this.properties[index].beneficiary_identification ?? "";
        this.beneficiario.beneficiary_phone =
          this.properties[index].beneficiary_phone ?? "";
      }

      this.$emit("selectProperty", property);
    },

    saveEvaluationProducer() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.evaluationForm.property_id > 0) {
        this.$emit("saveEvaluationProducer");
      } else {
        this.property_required = "El predio es requerido";
      }
    },
  },
  watch: {
    evaluationForm() {
      if (this.evaluationForm.property_id > 0 && this.isLoading) {
        this.isLoading = false;
        this.thereIsMunicipality = true;
        this.selectProperty(this.evaluationForm.property_id);
      }
    },
  },
});
