
















import Vue from "vue";
export default Vue.extend({
  props: {
    selected: {
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeSelectDocumentType(event: number) {
      this.$emit("changeSelectDocumentType", event);
    },
  },
});
