


































import { IEvaluation } from "@/data/Evaluations/IEvaluation";
import IPagination from "@/data/Pagination/IPagination";
import getDate from "@/utils/getData";
import Vue, { PropType } from "vue";
import Pagination from "../Utils/Pagination.vue";
export default Vue.extend({
  components: { Pagination },
  props: {
    evaluations: {
      type: Array as PropType<IEvaluation[]>,
    },
    pagination: {
      type: Object as PropType<IPagination>,
    },
  },
  methods: {
    seeEvaluation(id: number) {
      // this.$router.push({
      //   name: ""
      // })
      // console.log("ver evaluación");
      this.$router.push({
        name: "EvaluationEdit",
        params: { type: String(2), id: String(id) },
      });
    },
    fecha: function (dato: string): string {
      const value: string = getDate(new Date(dato));
      return value || "";
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.$emit("getEvaluations", page);
    },
  },
});
