






























import axios, { AxiosError } from "axios";
import Vue from "vue";
import MessageAlert from "../../components/Utils/MessageAlert.vue";
import RoleForm from "../../components/Roles/RoleForm.vue";
import Messages from "../../data/Messages/Messages";
import IRoleFormAttributes from "../../data/Roles/IRoleFormAttributes";
import IRoleFormErrorAttributes from "../../data/Roles/IRoleFormErrorAttributes";
import { mapActions } from "vuex";
export default Vue.extend({
  components: { RoleForm, MessageAlert },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  data() {
    const roleFormAttributes: IRoleFormAttributes = {
      name: "",
    };
    const roleFormErrorAttributes: IRoleFormErrorAttributes = {
      name: [],
    };
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    const listPermissions: string[] = [];
    return {
      roleFormAttributes,
      roleFormErrorAttributes,
      showMessageAlert: false,
      optionsMessageAlert,
      listPermissions,
    };
  },
  methods: {
    ...mapActions({
      createdAction: "notifications/created",
    }),
    goBack() {
      this.$router.push({ name: "Roles" });
    },
    saveRole() {
      this.roleFormErrorAttributes = {
        name: [],
      };
      const payload = {
        role: this.roleFormAttributes,
        permissions: this.listPermissions,
      };
      axios
        .post(`/api/roles`, payload)
        .then((res) => {
          this.createdAction();
          this.$router.push({ name: "RoleEdit", params: { id: res.data.id } });
        })
        .catch((reason: AxiosError) => {
          if (reason.response?.status === 422) {
            this.roleFormErrorAttributes = reason.response?.data.errors;
            this.optionsMessageAlert = Messages.INVALID_FORM_VALIDATION;
          } else if (reason.response?.status === 500) {
            this.optionsMessageAlert = Messages.INTERNAL_ERROR;
          }
          this.showMessageAlert = true;
        });
    },
    changePermissions(newSelected: string[]) {
      this.listPermissions = newSelected;
    },

    closeAlert() {
      this.showMessageAlert = false;
    },
  },
});
