










import { ISelectTechnicals } from "@/data/Users/IUser";
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  created() {
    this.getTechnicals();
  },
  data() {
    const technicals: ISelectTechnicals[] = [];
    return {
      technicals,
      selected: [],
    };
  },
  methods: {
    getTechnicals() {
      axios.get(`/api/select-users-technicals`).then((res) => {
        this.technicals = res.data as ISelectTechnicals[];
      });
    },
    onChange(events: ISelectTechnicals[]) {
      const ids: number[] = [];
      events.forEach((item: ISelectTechnicals) => ids.push(item.id));
      this.$emit("selectTechnicians", ids);
    },
  },
});
