











import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import axios from "axios";
import Vue from "vue";
import { mapActions } from "vuex";
import { IPropertyForm } from "../../data/Properties/Property";
import propertyForm from "./propertyForm.vue";
export default Vue.extend({
  components: { propertyForm },
  data() {
    const propertyForm: IPropertyForm = {
      name: "",
      sidewalk: "",
      latitude: "",
      longitude: "",
      altitude: "",
      area: "",
      model: "",
      beneficiary_name: "",
      beneficiary_identification: "",
      beneficiary_phone: "",
      municipality_id: 0,
      departamento_id: 0,
    };
    const municipalities: ISelectMunicipality[] = [];
    return { propertyForm, municipalities };
  },
  methods: {
    ...mapActions({
      handleClose: "handleClose",
    }),
    selectMunicipality(municipality: number) {
      this.propertyForm.municipality_id = municipality;
    },
    saveProperty() {
      axios.post(`api/properties`, this.propertyForm).then((res) => {
        this.handleClose();
      });
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.municipalities = municipalities;
      this.propertyForm.departamento_id = dpto;
      this.propertyForm.municipality_id = 0;
    },
  },
});
