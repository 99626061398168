export default function getDate(date: Date): string {
  const month = date.getMonth() + 1;
  const array = [month, date.getDate()];
  const timeComponents: string[] = [];
  array.forEach((id) => {
    const pad = id < 10 ? "0" : "";
    timeComponents.push(pad + id);
  });
  return (
    date.getFullYear().toString() +
    "-" +
    timeComponents[0] +
    "-" +
    timeComponents[1]
  );
}
