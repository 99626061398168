















































































import FormProject from "@/components/Projects/FormProject.vue";
import SelectPropertySearch from "@/components/Properties/SelectPropertySearch.vue";
import SelectTechnical from "@/components/Selects/SelectTechnical.vue";
import { IProjectRequest } from "@/data/IProject";
import IPagination from "@/data/Pagination/IPagination";
import { IPropertyList } from "@/data/Properties/Property";
import { ISelectTechnicals } from "@/data/Users/IUser";
import axios from "axios";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  components: {
    FormProject,
    SelectPropertySearch,
    SelectTechnical,
  },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    this.getProject();
    this.getTechnicals();
  },
  data() {
    const projectFormAttributes: IProjectRequest = {
      name: "",
      description: "",
      bpin: "",
      departamento_id: 0,
      visit_type: "",
    };
    const properties: IPropertyList[] = [];
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    const technicals: ISelectTechnicals[] = [];
    return {
      projectFormAttributes,
      properties,
      pagination,
      technicals,
      municipality: 0,
      property: 0,
      thereIsMunicipality: false,
      loading: true,
      loadingProj: true,
    };
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
    }),
    getProperties(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      this.properties = [];
      axios
        .get(`/api/properties/project/${this.$route.params.id}`)
        .then((res) => {
          this.properties = res.data as IPropertyList[];
          this.loading = false;
        });
    },
    getProject() {
      axios.get(`/api/projects/${this.$route.params.id}`).then((res) => {
        this.projectFormAttributes = res.data as IProjectRequest;
        this.loadingProj = false;
      });
      this.getProperties();
    },
    goBack() {
      this.$router.push({ name: "Projects" });
    },
    saveProject() {
      axios
        .put(
          `/api/projects/${this.$route.params.id}`,
          this.projectFormAttributes
        )
        .then((res) => {
          this.projectFormAttributes = res.data as IProjectRequest;
          this.getProperties();
          this.updatedAction();
        });
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getProperties(page);
    },
    addProperty(id: number) {
      axios
        .put(`/api/properties/${id}/project/${this.$route.params.id}`)
        .then(() => {
          this.getProperties();
        });
    },
    removeProperty(id: number) {
      axios
        .put(`/api/properties/${id}/project/${this.$route.params.id}/remove`)
        .then(() => {
          this.getProperties();
        });
    },
    selectDepartamento(dpto: number) {
      this.projectFormAttributes.departamento_id = dpto;
    },
    selectUserTechnical(user: number, property: number) {
      const index = this.properties.findIndex(
        (item: IPropertyList) => item.id == property
      );
      if (index >= 0) {
        this.properties[index].user_id = user;
      }
    },
    saveAssignationTechnical() {
      axios
        .put(`/api/assignment-technicals`, { properties: this.properties })
        .then(() => {
          this.getProperties();
        });
    },
    getTechnicals() {
      axios.get(`/api/select-users-technicals`).then((res) => {
        this.technicals = res.data as ISelectTechnicals[];
      });
    },
  },
});
