



import { ISelectTechnicals } from "@/data/Users/IUser";
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    selected: {
      type: Number,
      default: 0,
    },
    property: {
      type: Number,
      default: 0,
    },
    technicals: {
      type: Array as PropType<ISelectTechnicals[]>,
    },
  },
  computed: {
    seleccion: {
      get: function (): ISelectTechnicals {
        const index = this.technicals.findIndex(
          (item) => item.id == this.selected
        );
        console.log(index);
        return this.technicals[index];
      },
      set(newDpto: ISelectTechnicals) {
        console.log(newDpto);
        this.$emit("selectUserTechnical", newDpto.id, this.property);
      },
    },
  },
});
