











































































import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";
import Pagination from "../../components/Utils/Pagination.vue";
import IPagination from "../../data/Pagination/IPagination";
import { IVisitList } from "../../data/Visits/Visit";
export default Vue.extend({
  components: { Pagination },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
    this.getVisits();
  },
  data() {
    const visits: IVisitList[] = [];
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    return { visits, pagination };
  },
  methods: {
    getVisits(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      axios
        .get(`/api/visits${url}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          this.visits = res.data.data as IVisitList[];
          this.pagination = res.data.pagination as IPagination;
        });
    },
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getVisits(page);
    },
    createVisit() {
      this.$router.push({ name: "VisitCreate" });
    },
    seeVisit(id: number) {
      this.$router.push({ name: "VisitDetail", params: { id: String(id) } });
    },
    editVisit(id: number) {
      this.$router.push({ name: "VisitEdit", params: { id: String(id) } });
    },
    deleteVisit(id: number) {
      axios.delete(`/api/visits/${id}`).then(() => {
        this.getVisits();
      });
    },
  },
  computed: {
    ...mapGetters({
      token: "authenticated/token",
    }),
  },
});
