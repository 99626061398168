import { ISelectDepartamento } from "@/data/IDepartamento";
import { IProjectSelect } from "@/data/IProject";
import ISelected from "../resources/ISelected";

export default {
  namespaced: true,

  state: {
    departamentos: [],
    projects: [],
  },
  getters: {
    departamentos(state: ISelected): ISelectDepartamento[] {
      return state.departamentos;
    },
    projects(state: ISelected): IProjectSelect[] {
      return state.projects;
    },
  },
  mutations: {
    SET_IS_DEPARTAMENTOS(state: ISelected, value: ISelectDepartamento[]): void {
      state.departamentos = value;
    },
    SET_PROJECTS(state: ISelected, value: IProjectSelect[]): void {
      state.projects = value;
    },
  },
};
