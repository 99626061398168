










import Vue from "vue";
import NavbarNav from "./NavbarNav.vue";
import NavbarToggle from "./NavbarToggle.vue";

export default Vue.extend({
  components: {
    NavbarNav,
    NavbarToggle,
  },
  props: {
    stickyTop: {
      type: Boolean,
      default: true,
    },
  },
});
