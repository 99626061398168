

















import Vue from "vue";
export default Vue.extend({
  props: {
    coordenadas: {
      type: String,
      default: "BOGOTA",
    },
  },
});
