
















































































import { IEvaluation } from "@/data/Evaluations/IEvaluation";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import IPagination from "@/data/Pagination/IPagination";
import { IPropSelect } from "@/data/Properties/Property";
import { IVisit } from "@/data/Visits/Visit";
import getData from "@/utils/getData";
import axios from "axios";
import Vue from "vue";
import selectMunicipality from "../Municipalities/selectMunicipality.vue";
import SelectDepartamento from "../Selects/SelectDepartamento.vue";
import SelectProperty from "../Selects/SelectProperty.vue";
import EvaluationsByProperty from "./EvaluationsByProperty.vue";
import VisitsReports from "./VisitsReports.vue";
export default Vue.extend({
  components: {
    selectMunicipality,
    VisitsReports,
    EvaluationsByProperty,
    SelectDepartamento,
    SelectProperty,
  },
  data() {
    const dateRange = {
      from: "",
      to: "",
    };
    const visits: IVisit[] = [];
    const evaluations: IEvaluation[] = [];
    const paginationVisits: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    const paginationEval: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    const properties: IPropSelect[] = [];
    const municipalities: ISelectMunicipality[] = [];
    return {
      departamento: 0,
      municipality: 0,
      property: 0,
      thereIsMunicipality: false,
      dateRange,
      visits,
      evaluations,
      paginationVisits,
      paginationEval,
      properties,
      municipalities,
    };
  },
  methods: {
    selectProperty(property: number) {
      this.property = property;
    },
    selectMunicipality(mun: number, properties: IPropSelect[]) {
      this.properties = properties;
      this.municipality = mun;
      this.property = 0;
    },
    getVisits(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      const request = {
        from: getData(new Date(this.dateRange.from)),
        to: getData(new Date(this.dateRange.to)),
      };
      axios
        .post(`api/reports/property/${this.property}/visits${url}`, request)
        .then((res) => {
          this.visits = res.data.data;
          this.paginationVisits = res.data.pagination;
        });
    },
    getEvaluations(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      const request = {
        from: getData(new Date(this.dateRange.from)),
        to: getData(new Date(this.dateRange.to)),
      };
      axios
        .post(
          `api/reports/property/${this.property}/evaluations${url}`,
          request
        )
        .then((res) => {
          this.evaluations = res.data.data;
          this.paginationEval = res.data.pagination;
        });
    },
    searchReports() {
      this.getVisits();
      this.getEvaluations();
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.municipalities = municipalities;
      this.departamento = dpto;
      this.municipality = 0;
      this.property = 0;
    },
  },
});
