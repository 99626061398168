























































































import { ISideBarNavItems } from "@/data/menus/sidebarItems";
import Vue, { PropType } from "vue";

interface IMainSideBar {
  sidebarVisible: boolean;
  eventId: number;
}
export default Vue.extend({
  name: "main-sidebar",
  props: {
    hideLogoText: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<ISideBarNavItems[]>,
      required: true,
    },
  },
  data() {
    const data: IMainSideBar = {
      sidebarVisible: false,
      eventId: 0,
    };
    return {
      data,
    };
  },
  created() {
    this.data.eventId = this.$bus.$on(
      "toggle-sidebar",
      this.handleToggleSidebar
    );
  },
  beforeDestroy() {
    this.$bus.$off("toggle-sidebar", this.data.eventId);
  },
  methods: {
    handleToggleSidebar() {
      this.data.sidebarVisible = !this.data.sidebarVisible;
    },
  },
});
