





































import Vue from "vue";
import MainNavbar from "./LayoutPrivate/MainNavbar/MainNavbar.vue";
import MainSidebar from "./LayoutPrivate/MainSidebar/MainSidebar.vue";
import MainFooter from "./LayoutPrivate/MainFooter/MainFooter.vue";
import getSidebarItems from "../../data/menus/sidebarItems";
import { mapActions, mapGetters, mapState } from "vuex";
import ModalCreateProperty from "../Properties/modalCreateProperty.vue";
import MessageAlert from "../Utils/MessageAlert.vue";
export default Vue.extend({
  components: {
    MainNavbar,
    MainSidebar,
    MainFooter,
    ModalCreateProperty,
    MessageAlert,
  },
  // created() {
  //   this.userAuth();
  // },
  data() {
    return { sidebarItems: getSidebarItems() };
  },
  methods: {
    ...mapActions({
      userAuth: "authenticated/userAuth",
      handleClose: "handleClose",
      closeAlert: "notifications/closeAlert",
    }),
  },
  computed: {
    ...mapState(["showModal", "modal_title", "modal_body"]),
    ...mapGetters({
      showMessageAlert: "notifications/showMessageAlert",
      optionsMessageAlert: "notifications/optionsMessageAlert",
    }),
  },
});
