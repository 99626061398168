



import Vue, { PropType } from "vue";
import { ISelectMunicipality } from "../../data/Municipalities/Municipality";
export default Vue.extend({
  props: {
    selected: {
      default: 0,
    },
    municipalities: {
      type: Array as PropType<ISelectMunicipality[]>,
    },
  },
  updated() {
    if (this.selected > 0) {
      this.state = true;
    }
  },
  data() {
    return {
      state: false,
      ready: false,
    };
  },
  computed: {
    seleccion: {
      get: function (): ISelectMunicipality {
        const index = this.municipalities.findIndex(
          (item) => item.id == this.selected
        );
        return this.municipalities[index];
      },
      set(newDpto: ISelectMunicipality) {
        this.$emit("selectMunicipality", newDpto.id, newDpto.properties);
      },
    },
  },
});
