import { Ability } from "@casl/ability";

const items: string[] = [];
//eslint-disable-next-line
const rules: any = [];
items.forEach((item: string) => {
  rules.push({ action: item });
});

export default new Ability(rules);
