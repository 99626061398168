




































































import Vue from "vue";
import { IProjectList } from "@/data/IProject";
import IPagination from "@/data/Pagination/IPagination";
import Pagination from "@/components/Utils/Pagination.vue";
import axios from "axios";
export default Vue.extend({
  components: { Pagination },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    this.getProjects();
  },
  data() {
    const projects: IProjectList[] = [];
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    return { projects, pagination };
  },
  methods: {
    getProjects(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      axios.get(`/api/projects${url}`).then((res) => {
        this.projects = res.data.data as IProjectList[];
        this.pagination = res.data.pagination as IPagination;
      });
    },
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    createProject() {
      this.$router.push({ name: "ProjectCreate" });
    },
    editProject(id: number) {
      this.$router.push({ name: "ProjectEdit", params: { id: String(id) } });
    },
    deleteProject(id: number) {
      axios.delete(`/api/projects/${id}`).then(() => {
        this.getProjects();
      });
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getProjects(page);
    },
  },
});
