































































































































































































import Vue, { PropType } from "vue";
import IUserFormAttributes from "../../data/Users/IUserFormAttributes";
import IUserFormErrorAttributes from "../../data/Users/IUserFormErrorAttributes";
import SelectRole from "../Roles/SelectRole.vue";
import HelperTextCodeErrorApi from "../Utils/HelperTextCodeErrorApi.vue";
import selectDocumentType from "./selectDocumentType.vue";
import ValidationError from "../ValidationError.vue";
import { required, email, minValue } from "vuelidate/lib/validators";
import ValidatonErrorSelect from "../ValidatonErrorSelect.vue";
export default Vue.extend({
  components: {
    selectDocumentType,
    HelperTextCodeErrorApi,
    SelectRole,
    ValidationError,
    ValidatonErrorSelect,
  },
  props: {
    userFormAttributes: {
      type: Object as PropType<IUserFormAttributes>,
    },
    userFormErrorAttributes: {
      type: Object as PropType<IUserFormErrorAttributes>,
    },
  },
  data() {
    return { submitted: false };
  },
  validations: {
    userFormAttributes: {
      name: { required },
      email: { required, email },
      last_name: { required },
      phone: { required },
      document_type: { required, minValue: minValue(1) },
      document_number: { required },
      role: { required, minValue: minValue(1) },
      // name: { required },
      // sidewalk: { required },
      // latitude: { required, decimal, between: between(-90, 90) },
      // longitude: { required, decimal, between: between(-180, 180) },
      // altitude: { required, decimal, between: between(0, 4000) },
      // area: { required, minValue: minValue(1) },
      // model: { required },
      // beneficiary_name: { required },
      // beneficiary_identification: { required, numeric },
      // beneficiary_phone: { required },
      // departamento_id: { required, minValue: minValue(1) },
      // municipality_id: { required, minValue: minValue(1) },
    },
  },
  methods: {
    saveUser() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit("saveUser", this.userFormAttributes);
    },
    changeSelectDocumentType(document: number) {
      this.userFormAttributes.document_type = document;
    },
    changeRole(role: number) {
      this.$emit("changeRole", role);
    },
  },
});
