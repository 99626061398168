




















import FormProject from "@/components/Projects/FormProject.vue";
import { IProjectRequest } from "@/data/IProject";
import axios from "axios";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  components: { FormProject },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  data() {
    const projectFormAttributes: IProjectRequest = {
      name: "",
      description: "",
      bpin: "",
      departamento_id: 0,
      visit_type: "",
    };
    return { projectFormAttributes };
  },
  methods: {
    ...mapActions({
      createdAction: "notifications/created",
    }),
    goBack() {
      this.$router.push({ name: "Projects" });
    },
    saveProject() {
      axios.post(`/api/projects`, this.projectFormAttributes).then((res) => {
        this.createdAction();
        this.$router.push({ name: "ProjectEdit", params: { id: res.data.id } });
      });
    },
    selectDepartamento(dpto: number) {
      this.projectFormAttributes.departamento_id = dpto;
    },
  },
});
