


































































































































import { IProjectRequest } from "@/data/IProject";
import Vue, { PropType } from "vue";
import SelectDepartamento from "../Selects/SelectDepartamento.vue";
import ValidationError from "../ValidationError.vue";
import { required, minValue } from "vuelidate/lib/validators";
import ValidatonErrorSelect from "../ValidatonErrorSelect.vue";
export default Vue.extend({
  components: { SelectDepartamento, ValidationError, ValidatonErrorSelect },
  props: {
    projectFormAttributes: {
      type: Object as PropType<IProjectRequest>,
    },
  },
  data() {
    return { submitted: false };
  },
  validations: {
    projectFormAttributes: {
      visit_type: { required },
      name: { required },
      description: { required },
      bpin: { required },
      departamento_id: { required, minValue: minValue(1) },
    },
  },
  methods: {
    saveProject() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit("saveProject");
    },
    selectDepartamento(dpto: number) {
      this.$emit("selectDepartamento", dpto);
    },
  },
});
