import axios, { AxiosError } from "axios";
import ICredentials from "../resources/ICredentials";
import IStateAuthenticated from "../resources/IStateAuthenticated";
import IUserAuthenticated from "../resources/IUserAuthenticated";
import IUserErrorAuthenticated from "../resources/IUserErrorAuthenticated";
import ability from "@/ability";
const userInitial: IUserAuthenticated = {
  id: Number(localStorage.getItem("id_user")) || 0,
  name: localStorage.getItem("name_user") || "",
  email: localStorage.getItem("email_user") || "",
  document: localStorage.getItem("document_user") || "",
};
const userErrorInitial: IUserErrorAuthenticated = {
  id: [],
  name: [],
  email: [],
};
export default {
  namespaced: true,

  state: {
    isAuthenticated:
      localStorage.getItem("isAuthenticated") == "true" ? true : false || false,
    isLogued: false,
    user: userInitial,
    userError: userErrorInitial,
    token: localStorage.getItem("token") || "",
    permissions: localStorage.getItem("permissions") || "",
  },
  getters: {
    isAuthenticated(state: IStateAuthenticated): boolean {
      return state.isAuthenticated;
    },
    isLogued(state: IStateAuthenticated): boolean {
      return state.isLogued;
    },
    user(state: IStateAuthenticated): IUserAuthenticated {
      return state.user;
    },
    userError(state: IStateAuthenticated): IUserErrorAuthenticated {
      return state.userError;
    },
    token(state: IStateAuthenticated): string {
      return state.token;
    },
    permissions(state: IStateAuthenticated): string {
      return state.permissions;
    },
  },
  mutations: {
    SET_IS_AUTHENTICATED(state: IStateAuthenticated, value: boolean): void {
      state.isAuthenticated = value;
      value != null
        ? localStorage.setItem("isAuthenticated", String(value))
        : false;
    },
    SET_IS_LOGUED(state: IStateAuthenticated, value: boolean): void {
      state.isLogued = value;
    },
    SET_USER(state: IStateAuthenticated, value: IUserAuthenticated): void {
      state.user = value;
      // value!=null ? localStorage.setItem('',)
    },
    SET_USER_ERROR(
      state: IStateAuthenticated,
      value: IUserErrorAuthenticated
    ): void {
      state.userError = value;
    },
    SET_TOKEN(state: IStateAuthenticated, value: string): void {
      state.token = value;
    },
    SET_PERMISSIONS(state: IStateAuthenticated, value: string): void {
      //eslint-disable-next-line
      const rules: any = [];
      const items = JSON.parse(value);
      items.forEach((item: string) => {
        rules.push({ action: item });
      });
      ability.update(rules);
      state.permissions = value;
    },
  },
  actions: {
    //eslint-disable-next-line
    async signIn({ dispatch, commit }: any, credentials: ICredentials) {
      // await axios.get("/sanctum/csrf-cookie").then(() => {
      return axios
        .post("/api/login", credentials)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("id_user", res.data.user.id);
          localStorage.setItem("name_user", res.data.user.name);
          localStorage.setItem("email_user", res.data.user.email);
          localStorage.setItem("document_user", res.data.user.document);
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.user.permissions)
          );
          commit("SET_TOKEN", res.data.token);
          commit("SET_IS_LOGUED", true);
          commit("SET_IS_AUTHENTICATED", true);
          commit("SET_USER", res.data.user);
          commit("SET_PERMISSIONS", JSON.stringify(res.data.user.permissions));
        })
        .catch((reason: AxiosError) => {
          return commit("SET_USER_ERROR", reason.response?.data.errors);
        });
      // });
    },
    //eslint-disable-next-line
    userAuth({ commit }: any) {
      return axios
        .get("/api/user")
        .then((res) => {
          commit("SET_IS_AUTHENTICATED", true);
          commit("SET_USER", res.data.data);
        })
        .catch(() => {
          commit("SET_IS_AUTHENTICATED", false);
          commit("SET_USER", userInitial);
        });
    },
    //eslint-disable-next-line
    signOut({ commit, state }: any) {
      const token = {
        token: state.token,
      };
      return axios
        .post("/api/logout", token)
        .then(() => {
          localStorage.clear();
          commit("SET_IS_AUTHENTICATED", false);
          commit("SET_USER", userInitial);
          commit("SET_TOKEN", "");
        })
        .catch(() => {
          localStorage.clear();
          commit("SET_IS_AUTHENTICATED", false);
          commit("SET_USER", userInitial);
          commit("SET_TOKEN", "");
        });
    },
  },
};
