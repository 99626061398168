<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item" v-if="pagination.current_page > 1">
        <a class="page-link" @click="changePage(pagination.current_page - 1)">
          <span>Atras</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="(page, index) in pagesNumber"
        v-bind:class="[page == isActived ? 'active' : '']"
        :key="index"
      >
        <a class="page-link" @click="changePage(page)">
          {{ page }}
        </a>
      </li>
      <li
        class="page-item"
        v-if="pagination.current_page < pagination.last_page"
      >
        <a class="page-link" @click="changePage(pagination.current_page + 1)">
          <span>Siguiente</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    pagination: {
      default: {
        total: 0,
        current_page: 0,
        per_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
      },
    },
  },
  data() {
    return { offset: 2 };
  },
  methods: {
    changePage: function (page) {
      this.$emit("changePage", page);
    },
  },
  computed: {
    isActived: function () {
      return this.pagination.current_page;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }

      let from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }

      let to = from + this.offset * 2;
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      let pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  },
});
</script>
