import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MainPage from "../views/MainPage.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";
import MyProfile from "../views/Session/MyProfile.vue";
import ChangeMyPassword from "../views/Session/ChangeMyPassword.vue";
import RolesList from "../views/Roles/RolesList.vue";
import RoleCreate from "../views/Roles/RoleCreate.vue";
import RoleEdit from "../views/Roles/RoleEdit.vue";
import Municipalities from "../views/Municipalities/Municipalities.vue";
import UsersList from "../views/Users/UsersList.vue";
import UserCreate from "../views/Users/UserCreate.vue";
import UserEdit from "../views/Users/UserEdit.vue";
import TechnicianAssignment from "../views/Users/TechnicianAssignment.vue";
import Projects from "../views/Projects/Projects.vue";
import ProjectCreate from "../views/Projects/ProjectCreate.vue";
import ProjectEdit from "../views/Projects/ProjectEdit.vue";
import ListProperties from "../views/Properties/ListProperties.vue";
import CreateProperty from "../views/Properties/CreateProperty.vue";
import EditProperty from "../views/Properties/EditProperty.vue";
import VisitList from "../views/Visits/VisitList.vue";
import VisitCreate from "../views/Visits/VisitCreate.vue";
import VisitDetail from "../views/Visits/VisitDetail.vue";
import VisitEdit from "../views/Visits/VisitEdit.vue";
import Evaluations from "../views/Evaluations/Evaluations.vue";
import EvaluationCreate from "../views/Evaluations/EvaluationCreate.vue";
import EvaluationEdit from "../views/Evaluations/EvaluationEdit.vue";
import ReportsList from "../views/Reports/ReportsList.vue";
// import CurriculumVitaeList from "../views/CurriculumVitae/CurriculumVitaeList.vue";
import ability from "@/ability";
import authenticated from "../store/modules/authenticated";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "MainPage",
    component: MainPage,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      module: 0,
    },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: MyProfile,
    meta: {
      requiresAuth: true,
      module: 0,
    },
  },
  {
    path: "/change-my-password",
    name: "ChangeMyPassword",
    component: ChangeMyPassword,
    meta: {
      requiresAuth: true,
      module: 0,
    },
  },
  {
    path: "/roles",
    name: "Roles",
    component: RolesList,
    meta: {
      requiresAuth: true,
      module: 1,
    },
  },
  {
    path: "/roles/create",
    name: "RoleCreate",
    component: RoleCreate,
    meta: {
      requiresAuth: true,
      module: 1,
    },
  },
  {
    path: "/roles/:id/edit",
    name: "RoleEdit",
    component: RoleEdit,
    meta: {
      requiresAuth: true,
      module: 1,
    },
  },
  {
    path: "/municipalities",
    name: "Municipalities",
    component: Municipalities,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: UsersList,
    meta: {
      requiresAuth: true,
      module: 2,
    },
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: UserCreate,
    meta: {
      requiresAuth: true,
      module: 2,
    },
  },
  {
    path: "/users/:id/edit",
    name: "UserEdit",
    component: UserEdit,
    meta: {
      requiresAuth: true,
      module: 2,
    },
  },
  {
    path: "/users/asignacion-tecnicos",
    name: "TechnicianAssignment",
    component: TechnicianAssignment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/create",
    name: "ProjectCreate",
    component: ProjectCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:id/edit",
    name: "ProjectEdit",
    component: ProjectEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/properties",
    name: "ListProperties",
    component: ListProperties,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/properties/create",
    name: "CreateProperty",
    component: CreateProperty,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/properties/:id/edit",
    name: "EditProperty",
    component: EditProperty,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visits",
    name: "Visits",
    component: VisitList,
    meta: {
      requiresAuth: true,
      module: 3,
    },
  },
  {
    path: "/visits/create",
    name: "VisitCreate",
    component: VisitCreate,
    meta: {
      requiresAuth: true,
      module: 3,
    },
  },
  {
    path: "/visits/:id/detail",
    name: "VisitDetail",
    component: VisitDetail,
    meta: {
      requiresAuth: true,
      module: 3,
    },
  },
  {
    path: "/visits/:id/edit",
    name: "VisitEdit",
    component: VisitEdit,
    meta: {
      requiresAuth: true,
      module: 3,
    },
  },
  {
    path: "/evaluations",
    name: "Evaluations",
    component: Evaluations,
    meta: {
      requiresAuth: true,
      // module: 3,
    },
  },
  {
    path: "/evaluations/create",
    name: "EvaluationCreate",
    component: EvaluationCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/evaluations/:type/:id/edit",
    name: "EvaluationEdit",
    component: EvaluationEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsList,
    meta: {
      requiresAuth: true,
      module: 4,
    },
  },
  // {
  //   path: "/curriculum-vitae",
  //   name: "CurriculumVitae",
  //   component: CurriculumVitaeList,
  //   meta: {
  //     requiresAuth: true,
  //     module: 5,
  //   },
  // },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta?.requiresAuth;
  const token = localStorage.getItem("token") ?? "";

  const permissions = localStorage.getItem("permissions") ?? "";

  if (permissions !== "") {
    //eslint-disable-next-line
    const rules: any = [];
    const items = JSON.parse(permissions);
    items.forEach((item: string) => {
      rules.push({ action: item });
    });
    ability.update(rules);
  }

  if (requiresAuth) {
    if (token !== "") {
      // store.dispatch("me");
      if (authenticated.state.token !== "") {
        next();
      } else {
        localStorage.clear();
        next({ name: "Login" });
      }
    } else {
      localStorage.clear();
      next({ name: "Login" });
    }
  }
  next();
  // const requiresAuth = to.meta?.requiresAuth;
  // const hideForAuth = to.meta?.hideForAuth;
  // const isAuthenticated = authenticated.state.isAuthenticated;
  // if (hideForAuth && isAuthenticated) {
  //   next({ name: "Dashboard" });
  // }
  // if (requiresAuth && !isAuthenticated) {
  //   next({ name: "Login" });
  // }
  // next();
});

export default router;
