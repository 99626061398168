


































import IPagination from "@/data/Pagination/IPagination";
import { IVisit } from "@/data/Visits/Visit";
import Vue, { PropType } from "vue";
import Pagination from "../Utils/Pagination.vue";
export default Vue.extend({
  components: { Pagination },
  props: {
    visits: {
      type: Array as PropType<IVisit[]>,
    },
    pagination: {
      type: Object as PropType<IPagination>,
    },
  },
  methods: {
    seeVisit(id: number) {
      this.$router.push({ name: "VisitDetail", params: { id: String(id) } });
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.$emit("getVisits", page);
    },
  },
});
