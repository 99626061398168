










import { ISelectTechnicals } from "@/data/Users/IUser";
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  created() {
    this.getCoordinators();
  },
  data() {
    const coordinators: ISelectTechnicals[] = [];
    return {
      coordinators,
      selected: "",
    };
  },
  methods: {
    getCoordinators() {
      axios.get(`/api/select-users-coordinators`).then((res) => {
        this.coordinators = res.data as ISelectTechnicals[];
      });
    },
    onChange(event: ISelectTechnicals) {
      this.$emit("selectCoordinator", event.id);
    },
  },
});
