var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveProject.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-auto"},[_c('d-form-radio',{class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.projectFormAttributes.visit_type !== undefined &&
            _vm.$v.projectFormAttributes.visit_type.$error,
        },attrs:{"inline":"","value":"Investigación"},model:{value:(_vm.projectFormAttributes.visit_type),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "visit_type", $$v)},expression:"projectFormAttributes.visit_type"}},[_vm._v("Investigación")]),_c('d-form-radio',{attrs:{"inline":"","value":"Innovación"},model:{value:(_vm.projectFormAttributes.visit_type),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "visit_type", $$v)},expression:"projectFormAttributes.visit_type"}},[_vm._v("Innovación")]),_c('d-form-radio',{attrs:{"inline":"","value":"Apropiación"},model:{value:(_vm.projectFormAttributes.visit_type),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "visit_type", $$v)},expression:"projectFormAttributes.visit_type"}},[_vm._v("Apropiación social")])],1),_c('div',{staticClass:"col-12 col-md-auto"},[_c('d-input-group',{attrs:{"prepend":"Otro, ¿Cuál?"}},[_c('d-input',{model:{value:(_vm.projectFormAttributes.visit_type),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "visit_type", $$v)},expression:"projectFormAttributes.visit_type"}})],1),(
          _vm.$v.projectFormAttributes.visit_type !== undefined &&
          _vm.$v.projectFormAttributes.visit_type.$error
        )?_c('validaton-error-select',{attrs:{"propiedad":_vm.$v.projectFormAttributes.visit_type,"params":_vm.$v.projectFormAttributes.visit_type !== undefined &&
          _vm.$v.projectFormAttributes.visit_type.$params}}):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Nombre:")]),_c('d-input',{class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.projectFormAttributes.name !== undefined &&
            _vm.$v.projectFormAttributes.name.$error,
        },model:{value:(_vm.projectFormAttributes.name),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "name", $$v)},expression:"projectFormAttributes.name"}}),_c('validation-error',{attrs:{"propiedad":_vm.$v.projectFormAttributes.name,"params":_vm.$v.projectFormAttributes.name !== undefined &&
          _vm.$v.projectFormAttributes.name.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Descripción:")]),_c('d-input',{class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.projectFormAttributes.description !== undefined &&
            _vm.$v.projectFormAttributes.description.$error,
        },model:{value:(_vm.projectFormAttributes.description),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "description", $$v)},expression:"projectFormAttributes.description"}}),_c('validation-error',{attrs:{"propiedad":_vm.$v.projectFormAttributes.description,"params":_vm.$v.projectFormAttributes.description !== undefined &&
          _vm.$v.projectFormAttributes.description.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("BPIN:")]),_c('d-input',{class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.projectFormAttributes.bpin !== undefined &&
            _vm.$v.projectFormAttributes.bpin.$error,
        },attrs:{"type":"number","min":"0"},model:{value:(_vm.projectFormAttributes.bpin),callback:function ($$v) {_vm.$set(_vm.projectFormAttributes, "bpin", $$v)},expression:"projectFormAttributes.bpin"}}),_c('validation-error',{attrs:{"propiedad":_vm.$v.projectFormAttributes.bpin,"params":_vm.$v.projectFormAttributes.bpin !== undefined &&
          _vm.$v.projectFormAttributes.bpin.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Departamento:")]),_c('select-departamento',{attrs:{"selected":_vm.projectFormAttributes.departamento_id},on:{"selectDepartamento":_vm.selectDepartamento}}),(
          _vm.$v.projectFormAttributes.departamento_id !== undefined &&
          _vm.$v.projectFormAttributes.departamento_id.$error
        )?_c('validaton-error-select',{attrs:{"propiedad":_vm.$v.projectFormAttributes.departamento_id,"params":_vm.$v.projectFormAttributes.departamento_id !== undefined &&
          _vm.$v.projectFormAttributes.departamento_id.$params}}):_vm._e()],1)]),_c('div',{staticClass:"row mt-2 ml-0"},[_c('d-button',{attrs:{"type":"submit","value":"Guardar","pill":"","theme":"success"}},[_c('i',{staticClass:"material-icons"},[_vm._v("done")]),_vm._v(" Guardar")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }