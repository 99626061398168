









import Vue from "vue";
import NavbarNav from "./NavBarNav.vue";

export default Vue.extend({
  components: { NavbarNav },
  props: {
    stickyTop: {
      type: Boolean,
      default: true,
    },
  },
});
