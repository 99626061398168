











import Vue from "vue";
import { mapState } from "vuex";
import MessageAlert from "./components/Utils/MessageAlert.vue";
import PrivateLayout from "./components/Layouts/PrivateLayout.vue";
import PublicLayout from "./components/Layouts/PublicLayout.vue";
export default Vue.extend({
  components: { MessageAlert, PrivateLayout, PublicLayout },
  data() {
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return {
      optionsMessageAlert,
      showMessageAlert: false,
    };
  },
  methods: {
    closeAlert() {
      this.showMessageAlert = false;
    },
  },
  mounted() {
    window.addEventListener("message", (event) => {
      if (event.data.msg === "SW_UPDATED") {
        window.caches.keys().then((cache) => {
          cache.forEach((name) => {
            window.caches.delete(name);
          });
        });
        this.showMessageAlert = true;
      }
    });
  },
  computed: {
    ...mapState(["layout"]),
  },
});
