var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Nombres:")]),_c('d-input',{class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.userFormAttributes.name !== undefined &&
            _vm.$v.userFormAttributes.name.$error,
        },model:{value:(_vm.userFormAttributes.name),callback:function ($$v) {_vm.$set(_vm.userFormAttributes, "name", $$v)},expression:"userFormAttributes.name"}}),(
          _vm.userFormErrorAttributes.name !== undefined &&
          _vm.userFormErrorAttributes.name.length > 0
        )?_c('helper-text-code-error-api',{attrs:{"msg":_vm.userFormErrorAttributes.name[0]}}):_vm._e(),_c('validation-error',{attrs:{"propiedad":_vm.$v.userFormAttributes.name,"params":_vm.$v.userFormAttributes.name !== undefined &&
          _vm.$v.userFormAttributes.name.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Apellidos:")]),_c('d-input',{staticClass:"mb-2",class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.userFormAttributes.last_name !== undefined &&
            _vm.$v.userFormAttributes.last_name.$error,
        },model:{value:(_vm.userFormAttributes.last_name),callback:function ($$v) {_vm.$set(_vm.userFormAttributes, "last_name", $$v)},expression:"userFormAttributes.last_name"}}),(
          _vm.userFormErrorAttributes.last_name !== undefined &&
          _vm.userFormErrorAttributes.last_name.length > 0
        )?_c('helper-text-code-error-api',{attrs:{"msg":_vm.userFormErrorAttributes.last_name[0]}}):_vm._e(),_c('validation-error',{attrs:{"propiedad":_vm.$v.userFormAttributes.last_name,"params":_vm.$v.userFormAttributes.last_name !== undefined &&
          _vm.$v.userFormAttributes.last_name.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Correo electrónico:")]),_c('d-input',{staticClass:"mb-2",class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.userFormAttributes.email !== undefined &&
            _vm.$v.userFormAttributes.email.$error,
        },attrs:{"type":"email"},model:{value:(_vm.userFormAttributes.email),callback:function ($$v) {_vm.$set(_vm.userFormAttributes, "email", $$v)},expression:"userFormAttributes.email"}}),(
          _vm.userFormErrorAttributes.email !== undefined &&
          _vm.userFormErrorAttributes.email.length > 0
        )?_c('helper-text-code-error-api',{attrs:{"msg":_vm.userFormErrorAttributes.email[0]}}):_vm._e(),_c('validation-error',{attrs:{"propiedad":_vm.$v.userFormAttributes.email,"params":_vm.$v.userFormAttributes.email !== undefined &&
          _vm.$v.userFormAttributes.email.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Teléfono:")]),_c('d-input',{staticClass:"mb-2",class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.userFormAttributes.phone !== undefined &&
            _vm.$v.userFormAttributes.phone.$error,
        },attrs:{"type":"number","min":"0"},model:{value:(_vm.userFormAttributes.phone),callback:function ($$v) {_vm.$set(_vm.userFormAttributes, "phone", $$v)},expression:"userFormAttributes.phone"}}),(
          _vm.userFormErrorAttributes.phone !== undefined &&
          _vm.userFormErrorAttributes.phone.length > 0
        )?_c('helper-text-code-error-api',{attrs:{"msg":_vm.userFormErrorAttributes.phone[0]}}):_vm._e(),_c('validation-error',{attrs:{"propiedad":_vm.$v.userFormAttributes.phone,"params":_vm.$v.userFormAttributes.phone !== undefined &&
          _vm.$v.userFormAttributes.phone.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('select-document-type',{attrs:{"selected":_vm.userFormAttributes.document_type},on:{"changeSelectDocumentType":_vm.changeSelectDocumentType}}),(
          _vm.userFormErrorAttributes.document_type !== undefined &&
          _vm.userFormErrorAttributes.document_type.length > 0
        )?_c('helper-text-code-error-api',{attrs:{"msg":_vm.userFormErrorAttributes.document_type[0]}}):_vm._e(),(
          _vm.$v.userFormAttributes.document_type !== undefined &&
          _vm.$v.userFormAttributes.document_type.$error
        )?_c('validaton-error-select',{attrs:{"propiedad":_vm.$v.userFormAttributes.document_type,"params":_vm.$v.userFormAttributes.document_type !== undefined &&
          _vm.$v.userFormAttributes.document_type.$params}}):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Número de documento:")]),_c('d-input',{staticClass:"mb-2",class:{
          'is-invalid':
            _vm.submitted &&
            _vm.$v.userFormAttributes.document_number !== undefined &&
            _vm.$v.userFormAttributes.document_number.$error,
        },attrs:{"type":"number","min":"0"},model:{value:(_vm.userFormAttributes.document_number),callback:function ($$v) {_vm.$set(_vm.userFormAttributes, "document_number", $$v)},expression:"userFormAttributes.document_number"}}),(
          _vm.userFormErrorAttributes.document_number !== undefined &&
          _vm.userFormErrorAttributes.document_number.length > 0
        )?_c('helper-text-code-error-api',{attrs:{"msg":_vm.userFormErrorAttributes.document_number[0]}}):_vm._e(),_c('validation-error',{attrs:{"propiedad":_vm.$v.userFormAttributes.document_number,"params":_vm.$v.userFormAttributes.document_number !== undefined &&
          _vm.$v.userFormAttributes.document_number.$params}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('select-role',{attrs:{"selected":_vm.userFormAttributes.role},on:{"changeRole":_vm.changeRole}}),(
          _vm.$v.userFormAttributes.role !== undefined &&
          _vm.$v.userFormAttributes.role.$error
        )?_c('validaton-error-select',{attrs:{"propiedad":_vm.$v.userFormAttributes.role,"params":_vm.$v.userFormAttributes.role !== undefined &&
          _vm.$v.userFormAttributes.role.$params}}):_vm._e()],1)]),_c('div',{staticClass:"row mt-2 ml-0"},[_c('d-button',{attrs:{"type":"submit","value":"Guardar","pill":"","theme":"success"}},[_c('i',{staticClass:"material-icons"},[_vm._v("done")]),_vm._v(" Guardar")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }