










import Vue from "vue";
import IUser from "@/data/Users/IUser";
import axios from "axios";
export default Vue.extend({
  props: {
    selected: {
      default: 0,
    },
  },
  created() {
    axios.get("/api/select-users").then((res) => {
      this.users = res.data;
    });
  },
  updated() {
    if (this.selected > 0) {
      this.state = true;
    }
  },
  data() {
    const users: IUser[] = [];
    return { users, state: false };
  },
  methods: {
    onChange(event: number) {
      if (event > 0) {
        this.state = true;
      }
      this.$emit("selectUser", event);
    },
  },
});
