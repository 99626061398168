

























































import Pagination from "@/components/Utils/Pagination.vue";
import IPagination from "@/data/Pagination/IPagination";
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  components: { Pagination },
  mounted() {
    this.getEvaluations();
  },
  data() {
    const evaluations: any[] = []; //IEvaluationList
    const pagination: IPagination = {
      total: 1,
      current_page: 1,
      per_page: 5,
      last_page: 1,
      from: 1,
      to: 1,
    };
    return { evaluations, pagination };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    createEvaluation() {
      this.$router.push({ name: "EvaluationCreate" });
    },
    getEvaluations(page?: number, query?: string) {
      const url =
        query !== undefined ? `?page=${page}${query}` : `?page=${page}`;
      axios.get(`api/evaluations`).then((res) => {
        this.evaluations = res.data;
      });
    },
    changePage: function (page: number) {
      this.pagination.current_page = page;
      this.getEvaluations(page);
    },
    editEvaluation(type: string, id: number) {
      this.$router.push({
        name: "EvaluationEdit",
        params: { type: type, id: String(id) },
      });
    },
  },
});
