




import { IProjectSelect } from "@/data/IProject";
import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  props: {
    selected: {
      type: Number,
      default: 0,
    },
  },
  created() {
    axios
      .get("/api/select-projects", {
        headers: { Authorization: `Bearer ${this.token}` },
      })
      .then((res) => {
        this.projects = res.data;
        this.$store.commit("selecteds/SET_PROJECTS", this.projects);
      });
  },
  data() {
    const projects: IProjectSelect[] = [];
    return {
      projects,
    };
  },
  computed: {
    seleccion: {
      get: function (): IProjectSelect {
        const index = this.projects.findIndex(
          (item) => item.id == this.selected
        );
        return this.projects[index];
      },
      set(newProj: IProjectSelect) {
        this.$emit("selectProject", newProj);
      },
    },
    ...mapGetters({
      token: "authenticated/token",
    }),
  },
});
