












import Vue from "vue";
import MainNavbar from "./LayoutPublic/MainNavbar/MainNavbar.vue";
import MainFooter from "./LayoutPublic/MainFooter/MainFooter.vue";
export default Vue.extend({
  components: { MainNavbar, MainFooter },
});
