



import Vue from "vue";

export default Vue.extend({
  created() {
    this.$store.commit("SET_LAYOUT", "public-layout");
  },
});
