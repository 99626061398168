

















































import FormEvaluationProducer from "@/components/Evaluations/FormEvaluationProducer.vue";
import FormEvaluationTechnical from "@/components/Evaluations/FormEvaluationTechnical.vue";
import {
  IEvaluationForm,
  IEvaluationTechnicalForm,
} from "@/data/Evaluations/IEvaluation";
import { ISelectDepartamento } from "@/data/IDepartamento";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import { IPropSelect } from "@/data/Properties/Property";
import axios from "axios";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  components: { FormEvaluationProducer, FormEvaluationTechnical },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    // this.getEvaluationTypes();
    this.getEvaluation();
  },

  data() {
    const evaluation_types: { id: number; name: string }[] = [
      { id: 1, name: "Beneficiario" },
      { id: 2, name: "Técnico o profesional" },
    ];
    const evaluationProducerForm: IEvaluationForm = {
      property_id: 0,
      municipality: 0,
      departamento_id: 0,
      califications: [],
      evaluation_type_id: 1,
    };
    const evaluationTechnicalForm: IEvaluationTechnicalForm = {
      user_id: 0,
      califications: [],
      evaluation_type_id: 2,
      aspects: {
        technical_aspects: [],
        social_aspects: [],
        administrative_aspects: [],
      },
    };
    const municipalities: ISelectMunicipality[] = [];
    const properties: IPropSelect[] = [];
    return {
      selected: 0,
      state: false,
      evaluation_types,
      evaluationProducerForm,
      evaluationTechnicalForm,
      isReady: false,
      municipalities,
      properties,
      loading: true,
      loadMun: true,
    };
  },
  computed: {
    ...mapGetters({
      departamentos: "selecteds/departamentos",
    }),
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
    }),
    getEvaluation() {
      if (this.$route.params.type == "1") {
        axios
          .get(`api/producers/${this.$route.params.id}`)
          .then((res) => {
            this.evaluationProducerForm = res.data as IEvaluationForm;
            if (this.evaluationProducerForm.califications.length == 0) {
              console.log("longitud 0");
              this.getIndicators(1);
            }
            this.selected = res.data.evaluation_type_id;
            this.isReady = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$route.params.type == "2") {
        axios
          .get(`api/technicians/${this.$route.params.id}`)
          .then((res) => {
            console.log(res.data);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$router.push({ name: "Evaluations" });
      }
    },
    goBack() {
      this.$router.push({ name: "Evaluations" });
    },
    onChange(value: number) {
      if (value > 0) {
        this.state = true;
      }
      this.selected = value;
      this.getIndicators(value);
    },
    getEvaluationTypes() {
      this.evaluation_types = [
        { id: 1, name: "Beneficiario" },
        { id: 2, name: "Técnico o profesional" },
      ];
    },
    getIndicators(type: number) {
      if (type > 0) {
        axios.get(`api/evaluations/${type}`).then((res) => {
          if (type == 1) {
            this.evaluationProducerForm.califications = res.data.indicators;
          } else if (type == 2) {
            this.evaluationTechnicalForm.califications = res.data.indicators;
          }
        });
      }
    },
    saveEvaluationProducer() {
      if (this.evaluationProducerForm.property_id > 0) {
        axios.put(`api/producers`, this.evaluationProducerForm).then(() => {
          this.updatedAction();
        });
      }
    },
    saveEvaluationTechnical() {
      axios.post(`api/technicians`, this.evaluationTechnicalForm).then(() => {
        this.updatedAction();
      });
    },
    selectProperty(prop: number) {
      this.evaluationProducerForm.property_id = prop;
    },
    selectMunicipality(mun: number, properties: IPropSelect[]) {
      this.properties = properties;
      this.evaluationProducerForm.municipality = mun;
      this.evaluationProducerForm.property_id = 0;
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.municipalities = municipalities;
      this.evaluationProducerForm.departamento_id = dpto;
      this.evaluationProducerForm.municipality = 0;
      this.evaluationProducerForm.property_id = 0;
    },
  },
  watch: {
    departamentos() {
      if (!this.loading && this.departamentos.length > 0) {
        const index = this.departamentos.findIndex(
          (item: ISelectDepartamento) =>
            item.id == this.evaluationProducerForm.departamento_id
        );
        this.municipalities = this.departamentos[index].municipalities;
      }
    },
    municipalities() {
      if (
        this.evaluationProducerForm.municipality > 0 &&
        this.municipalities.length > 0
      ) {
        const index2 = this.municipalities.findIndex(
          (item: ISelectMunicipality) =>
            item.id == this.evaluationProducerForm.municipality
        );
        this.properties = this.municipalities[index2].properties;
      }
    },
  },
});
