






























import Vue from "vue";
import UserForm from "../../components/Users/UserForm.vue";
import MessageAlert from "../../components/Utils/MessageAlert.vue";
import IUserFormAttributes from "../../data/Users/IUserFormAttributes";
import IUserFormErrorAttributes from "../../data/Users/IUserFormErrorAttributes";
import Messages from "../../data/Messages/Messages";
import axios, { AxiosError } from "axios";
import { mapActions } from "vuex";
export default Vue.extend({
  components: { UserForm, MessageAlert },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  data() {
    const userFormAttributes: IUserFormAttributes = {
      name: "",
      email: "",
      last_name: "",
      phone: "",
      password: "password",
      document_type: 1,
      document_number: "",
      role: 0,
    };
    const userFormErrorAttributes: IUserFormErrorAttributes = {
      name: [],
      email: [],
      last_name: [],
      phone: [],
      password: [],
      document_type: [],
      document_number: [],
    };
    const optionsMessageAlert = {
      theme: "light",
      message: "",
    };
    return {
      userFormAttributes,
      userFormErrorAttributes,
      showMessageAlert: false,
      optionsMessageAlert,
    };
  },
  methods: {
    ...mapActions({
      createdAction: "notifications/created",
    }),
    goBack() {
      this.$router.push({ name: "Users" });
    },
    saveUser() {
      this.userFormErrorAttributes = {
        name: [],
        email: [],
        last_name: [],
        phone: [],
        password: [],
        document_type: [],
        document_number: [],
      };
      axios
        .post(`/api/users`, this.userFormAttributes)
        .then((res) => {
          this.createdAction();
          this.$router.push({ name: "UserEdit", params: { id: res.data.id } });
        })
        .catch((reason: AxiosError) => {
          if (reason.response?.status === 422) {
            this.userFormErrorAttributes = reason.response?.data.errors;
            this.optionsMessageAlert = Messages.INVALID_FORM_VALIDATION;
          } else if (reason.response?.status === 500) {
            this.optionsMessageAlert = Messages.INTERNAL_ERROR;
          }
          this.showMessageAlert = true;
        });
    },

    closeAlert() {
      this.showMessageAlert = false;
    },
    changeRole(role: number) {
      this.userFormAttributes.role = role;
    },
  },
});
