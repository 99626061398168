



import { PropertyForMunicipality } from "@/data/Properties/Property";
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    selected: {
      default: 0,
    },
    properties: {
      type: Array as PropType<PropertyForMunicipality[]>,
    },
  },
  computed: {
    seleccion: {
      get: function (): PropertyForMunicipality {
        const index = this.properties.findIndex(
          (item) => item.id == this.selected
        );
        return this.properties[index];
      },
      set(newDpto: PropertyForMunicipality) {
        this.$emit("selectProperty", newDpto.id);
      },
    },
  },
});
