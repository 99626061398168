var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{class:[
    'main-sidebar',
    'col-12',
    'col-md-3',
    'col-lg-2',
    'px-0',
    _vm.data.sidebarVisible ? 'open' : '' ]},[_c('div',{staticClass:"main-navbar"},[_c('nav',{staticClass:"navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0"},[_c('a',{staticClass:"navbar-brand w-100 mr-0",staticStyle:{"line-height":"25px"},attrs:{"href":"/"}},[_c('div',{staticClass:"d-table m-auto"},[_c('img',{staticClass:"d-inline-block align-top mr-1",staticStyle:{"max-width":"25px"},attrs:{"id":"main-logo","src":require("@/assets/logo.png"),"alt":"Shards Dashboard"}}),(!_vm.hideLogoText)?_c('span',{staticClass:"d-none d-md-inline ml-1"},[_vm._v("Job Tracking")]):_vm._e()])]),_c('a',{staticClass:"toggle-sidebar d-sm-inline d-md-none d-lg-none",on:{"click":function($event){return _vm.handleToggleSidebar()}}},[_c('i',{staticClass:"material-icons"},[_vm._v("")])])])]),_c('div',{staticClass:"nav-wrapper"},[_c('d-nav',{staticClass:"flex-column"},_vm._l((_vm.items),function(item,navItemIdx){return _c('li',{key:navItemIdx,staticClass:"nav-item dropdown"},[_c('d-link',{directives:[{name:"d-toggle",rawName:"v-d-toggle",value:(("snc-" + navItemIdx)),expression:"`snc-${navItemIdx}`"}],class:[
            'nav-link',
            item.items && item.items.length ? 'dropdown-toggle' : '' ],attrs:{"to":item.to},on:{"click":function($event){!item.items && _vm.handleToggleSidebar()}}},[(item.htmlBefore)?_c('div',{staticClass:"item-icon-wrapper",domProps:{"innerHTML":_vm._s(item.htmlBefore)}}):_vm._e(),(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._e(),(item.htmlAfter)?_c('div',{staticClass:"item-icon-wrapper",domProps:{"innerHTML":_vm._s(item.htmlAfter)}}):_vm._e()]),(item.items && item.items.length)?_c('d-collapse',{staticClass:"dropdown-menu dropdown-menu-small",attrs:{"id":("snc-" + navItemIdx),"accordion":"sidebar-items-accordion"}},_vm._l((item.items),function(subItem,subItemIdx){return _c('d-link',{key:subItemIdx,staticClass:"dropdown-item",attrs:{"to":subItem.to},on:{"click":function($event){return _vm.handleToggleSidebar()}}},[_vm._v(_vm._s(subItem.title))])}),1):_vm._e()],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }