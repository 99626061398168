
























import { ISelectDepartamento } from "@/data/IDepartamento";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import axios from "axios";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import propertyForm from "../../components/Properties/propertyForm.vue";
import { IPropertyForm } from "../../data/Properties/Property";
export default Vue.extend({
  components: { propertyForm },
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  mounted() {
    this.getProperty();
  },
  data() {
    const propertyForm: IPropertyForm = {
      name: "",
      sidewalk: "",
      latitude: "",
      longitude: "",
      altitude: "",
      area: "",
      model: "",
      beneficiary_name: "",
      beneficiary_identification: "",
      beneficiary_phone: "",
      departamento_id: 0,
      municipality_id: 0,
    };
    const municipalities: ISelectMunicipality[] = [];
    return { propertyForm, municipalities, loading: true };
  },
  computed: {
    ...mapGetters({
      departamentos: "selecteds/departamentos",
    }),
  },
  methods: {
    ...mapActions({
      updatedAction: "notifications/updated",
    }),
    getProperty() {
      axios
        .get(`/api/properties/${this.$route.params.id}`)
        .then((res) => {
          this.propertyForm = {
            name: res.data.name,
            sidewalk: res.data.sidewalk,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            altitude: res.data.altitude,
            area: res.data.area,
            model: res.data.model,
            beneficiary_name: res.data.beneficiary_name,
            beneficiary_identification: res.data.beneficiary_identification,
            beneficiary_phone: res.data.beneficiary_phone,
            departamento_id: res.data.departamento_id,
            municipality_id: res.data.municipality_id,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.push({ name: "ListProperties" });
    },
    selectMunicipality(municipality: number) {
      this.propertyForm.municipality_id = municipality;
    },
    saveProperty() {
      axios
        .put(`api/properties/${this.$route.params.id}`, this.propertyForm)
        .then(() => {
          this.updatedAction();
        });
    },
    selectDepartamento(dpto: number, municipalities: ISelectMunicipality[]) {
      this.municipalities = municipalities;
      this.propertyForm.departamento_id = dpto;
      this.propertyForm.municipality_id = 0;
    },
  },
  watch: {
    departamentos() {
      if (!this.loading && this.departamentos.length > 0) {
        const index = this.departamentos.findIndex(
          (item: ISelectDepartamento) =>
            item.id == this.propertyForm.departamento_id
        );
        this.municipalities = this.departamentos[index].municipalities;
      }
    },
  },
});
