




















import { ISelectDepartamento } from "@/data/IDepartamento";
import { ISelectMunicipality } from "@/data/Municipalities/Municipality";
import { IPropertySelect } from "@/data/Properties/Property";
import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  mounted() {
    this.getProperties();
  },
  props: {
    departamento_id: {
      type: Number,
    },
  },
  data() {
    const properties: IPropertySelect[] = [];
    const selected: IPropertySelect = { id: 0, name: "", fullname: "" };
    return { properties, selected };
  },
  methods: {
    getProperties() {
      axios.get(`/api/select-departamentos`).then((res) => {
        const departamentos: ISelectDepartamento[] = res.data;
        const index = departamentos.findIndex(
          (item: ISelectDepartamento) => item.id == this.departamento_id
        );
        if (index >= 0) {
          departamentos[index].municipalities.forEach(
            (mun: ISelectMunicipality) => {
              mun.properties.forEach((prop: IPropertySelect) => {
                this.properties.push(prop);
              });
            }
          );
        }
      });
    },
    addProperty() {
      if (this.selected.id > 0) {
        this.$emit("addProperty", this.selected.id);
      }
    },
  },
});
