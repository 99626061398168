


















































import axios, { AxiosError } from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";
import Messages from "../../data/Messages/Messages";
export default Vue.extend({
  created() {
    this.$store.commit("SET_LAYOUT", "private-layout");
  },
  data() {
    const dataResetPassword = {
      password_current: "",
      password_new: "",
      password_confirm: "",
    };
    return { dataResetPassword };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Dashboard" });
    },
    changePassword() {
      axios
        .put(`/api/my-profile/change-my-password`, this.dataResetPassword, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          if (res.statusText === "Accepted") {
            this.$store.commit(
              "notifications/SET_OPTIONS_MESSAGE_ALERT",
              Messages.SUCCESS_UPDATED
            );
            this.$store.commit("notifications/SET_SHOW_MESSAGE_ALERT", true);
          }
        })
        .catch((reason: AxiosError) => {
          if (reason.response?.statusText === "Bad Request") {
            this.$store.commit(
              "notifications/SET_OPTIONS_MESSAGE_ALERT",
              Messages.INVALID_FORM_VALIDATION
            );
          } else if (reason.response?.statusText === "Internal Server Error") {
            this.$store.commit(
              "notifications/SET_OPTIONS_MESSAGE_ALERT",
              Messages.INTERNAL_ERROR
            );
          } else if (reason.response?.status == 422) {
            this.$store.commit("notifications/SET_OPTIONS_MESSAGE_ALERT", {
              message:
                "La contraseña proporcionada no coincide con nuestros registros.",
              theme: "danger",
            });
          }
          this.$store.commit("notifications/SET_SHOW_MESSAGE_ALERT", true);
        });
    },
  },
  computed: {
    ...mapGetters({
      token: "authenticated/token",
    }),
  },
});
