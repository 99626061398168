var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-container',{staticClass:"main-content-container px-4",attrs:{"fluid":""}},[_c('div',{staticClass:"container mt-2"},[_c('d-card',[_c('d-card-header',[_c('d-button',{attrs:{"pill":"","outline":"","theme":"light"},on:{"click":_vm.goBack}},[_vm._v("← Regresar")])],1),_c('d-card-body',{attrs:{"title":"Asignación de técnicos"}},[_c('div',{staticClass:"mt-2"},[(_vm.showMessageAlert)?_c('message-alert',{attrs:{"theme":_vm.optionsMessageAlert.theme,"message":_vm.optionsMessageAlert.message},on:{"closeAlert":_vm.closeAlert}}):_vm._e()],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.associateTechnicals.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Coordinador")]),_c('select-coordinador',{on:{"selectCoordinator":_vm.selectCoordinator}}),(
                  _vm.$v.asignacion.coordinator !== undefined &&
                  _vm.$v.asignacion.coordinator.$error &&
                  _vm.submitted
                )?_c('validaton-error-select',{attrs:{"propiedad":_vm.$v.asignacion.coordinator,"params":_vm.$v.asignacion.coordinator !== undefined &&
                  _vm.$v.asignacion.coordinator.$params}}):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":""}},[_vm._v("Técnicos")]),_c('selet-technician-multiple',{on:{"selectTechnicians":_vm.selectTechnicians}}),(
                  _vm.$v.asignacion.tecnicos !== undefined &&
                  _vm.$v.asignacion.tecnicos.$error
                )?_c('validaton-error-select',{attrs:{"propiedad":_vm.$v.asignacion.tecnicos,"params":_vm.$v.asignacion.tecnicos !== undefined &&
                  _vm.$v.asignacion.tecnicos.$params}}):_vm._e()],1)]),_c('div',{staticClass:"mt-3",staticStyle:{"text-align":"right"}},[_c('d-button',{attrs:{"pill":"","theme":"success"}},[_c('i',{staticClass:"material-icons"},[_vm._v("add")]),_vm._v(" Asociar técnicos")])],1)]),_c('hr'),_c('div',{staticClass:"table-responsive mt-2"},[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Coordinador")]),_c('th',[_vm._v("Técnicos")])])]),_c('tbody',[(_vm.users.length <= 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("No se encontraron registros")])]):_vm._e(),_vm._l((_vm.users),function(p,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"td"},[_vm._v(_vm._s(p.coordinator))]),_c('td',{staticClass:"td"},_vm._l((p.tecnicos),function(t,i){return _c('d-badge',{key:i,staticClass:"ml-1 mb-1",attrs:{"theme":"primary"}},[_vm._v(_vm._s(t))])}),1)])})],2)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }